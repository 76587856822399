import { useQuery } from '@apollo/client';
import type { DocumentNode } from '@apollo/client';
import { IconArrowBarRight, IconArrowBarToLeft } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClassType, SearchFeature, InputMaybe, SearchResponsePageType } from '../../../__generated__/graphql';
import { FAVOURITE_CLASSES_QUERY } from '../../../api/queries/etimReleases';
import {
  SEARCH_UNIVERSAL_QUERY,
  SEARCH_UNIVERSAL_BY_QUOTATION_QUERY,
  SEARCH_BY_DESCRIPTION_QUERY,
} from '../../../api/queries/searchRecords';
import { useDebounceArray } from '../../../helpers/customHooks';
import { useAppSelector } from '../../../helpers/reduxHooks';
import FilterFeatures from '../../search/filters/featuresFilter/FeaturesFilter';
import { EtimClass } from '../overlays/EtimClass';


interface Props {
  setPageData: any
  setSearchRecordsLoading: React.Dispatch<React.SetStateAction<boolean>>
}
interface SearchVariablesType {
  page: number,
  etimClass: string | undefined,
  searchInput: InputMaybe<InputMaybe<string> | InputMaybe<string>[]>,
  searchFeatures: SearchFeature[] | undefined,
  project: string | undefined,
  quotation: string | undefined,
  etimCatalog: string | undefined,
  searchForType: string,
}

export default function ETIMFilter(props: Props) {
  const { setPageData, setSearchRecordsLoading } = props;
  const [isClassesOpen, setIsClassesOpen] = useState(false);
  const [isFeaturesOpen, setIsFeaturesOpen] = useState(true);
  const { t } = useTranslation();
  const isComposeMode = useAppSelector(state => state.quotation.isComposeMode);


  const selectedFeatures = useAppSelector(state => state.search.selectedFeatures);
  const selectedETIMClassId = useAppSelector(state => state.search.selectedETIMClassId);
  const searchInputValue = useAppSelector(state => state.search.searchInputValue);
  const QuotationContext = useAppSelector(state => state.search.QuotationContext);
  const debouncedSearchInput = useDebounceArray(searchInputValue, 200);
  const searchListPage = useAppSelector(state => state.search.searchListPage);
  const searchResultsType = useAppSelector(state => state.search.searchResultsType);

  let searchFeatures: SearchFeature[] = [];
  if (selectedFeatures.length) {
    searchFeatures = selectedFeatures.map(feature => {
      return { code: feature.code, featureType: feature.featureType, userValue: feature.userValue };
    });
  }

  const searchVariables: SearchVariablesType = {
    page: searchListPage,
    searchInput: debouncedSearchInput as InputMaybe<InputMaybe<string> | InputMaybe<string>[]>,
    searchForType: searchResultsType,
    etimClass: undefined,
    searchFeatures: undefined,
    project: undefined,
    quotation: undefined,
    etimCatalog: undefined,
  };

  if (QuotationContext && QuotationContext.searchProjectId && QuotationContext.searchQuotationId) {
  }

  const optionalSearchValues = {
    project: QuotationContext?.searchProjectId,
    quotation: QuotationContext?.searchQuotationId,
    etimCatalog: QuotationContext?.searchETIMCatalogId,
  };

  let searchRecordsQuery: DocumentNode = SEARCH_BY_DESCRIPTION_QUERY;
  if (optionalSearchValues.project && optionalSearchValues.quotation) {
    searchRecordsQuery = SEARCH_UNIVERSAL_BY_QUOTATION_QUERY;
    searchVariables.project = QuotationContext.searchProjectId;
    searchVariables.quotation = QuotationContext.searchQuotationId;
  } else if (selectedETIMClassId) {
    searchRecordsQuery = SEARCH_UNIVERSAL_QUERY;
    searchVariables.etimClass = selectedETIMClassId;
    searchVariables.searchFeatures = searchFeatures;
  }
  if (QuotationContext && QuotationContext.searchETIMCatalogId) {
    searchVariables.etimCatalog = QuotationContext.searchETIMCatalogId;
  }

  const shouldSkipRequest = () => {
    if (searchRecordsQuery === SEARCH_UNIVERSAL_BY_QUOTATION_QUERY) {
      return !QuotationContext?.searchQuotationId;
    } else if (searchRecordsQuery === SEARCH_UNIVERSAL_QUERY) {
      return !selectedETIMClassId;
    } else if (searchRecordsQuery === SEARCH_BY_DESCRIPTION_QUERY) {
      return debouncedSearchInput.length === 0;
    }
    // Default behavior: don't skip
    return false;
  };

  // Keep here network-only fetch policy in order to update Features filter
  // on Alphanumeric features DESELECT.  
  const {
    data: searchRecordsData,
    loading: searchRecordsLoading,
  } = useQuery(searchRecordsQuery, {
    variables: searchVariables,
    skip: shouldSkipRequest(),
    fetchPolicy: 'network-only',
  });

  const {
    data: classesData,
  } = useQuery(FAVOURITE_CLASSES_QUERY, {
    variables: {
    },
    skip: !isComposeMode,
  });

  //@ts-ignore
  const features: SearchFeatureType[] | undefined = searchRecordsData?.[Object.keys(searchRecordsData)[0]]?.response?.features;

  useEffect(() => {
    if (searchRecordsData
      //@ts-ignore
      && searchRecordsData[Object.keys(searchRecordsData)[0]].response) {
      //@ts-ignore
      setPageData(searchRecordsData[Object.keys(searchRecordsData)[0]].response as SearchResponsePageType);
    }
  }, [searchRecordsData, setPageData]);

  useEffect(() => {
    setSearchRecordsLoading(searchRecordsLoading);
  }, [searchRecordsLoading, setSearchRecordsLoading]);

  return (
    <>
      {isClassesOpen ?
        <div className='flex flex-col'>
          <div
            className="sticky top-0 z-20 flex items-center bg-cblue-100 border-t-2 border-b-2 border-cblue-500 pt-3 pb-3 mb-3 font-bold text-sm text-cgray-600"
          >
            <IconArrowBarToLeft className="w-6 h-6 ml-4 cursor-pointer" onClick={() => setIsClassesOpen(false)} />
            <div className="w-36 mt-0.5 mb-0.5 h-5 ml-2">{t('Favorite Classes')}</div>
          </div>
          <div className="grow w-72 pl-2 flex flex-col gap-2 pr-2 border-r-2 border-cgray-300 h-full max-h-screen overflow-y-scroll overflow-x-clip">
            {classesData && classesData.favouriteClasses && classesData.favouriteClasses.response
              && (classesData.favouriteClasses.response.map(
                (option) => (<EtimClass key={option?.id} etimClass={option as ClassType} />))
              )
            }
          </div>
        </div>
        :
        <div onClick={() => setIsClassesOpen(true)}>
          <div className="sticky top-0 z-20 flex items-center bg-cblue-100 border-t-2 border-b-2 border-cblue-500 pt-3 pb-3 mb-3 font-bold text-sm text-cgray-600">
            <IconArrowBarRight className="w-6 h-6 ml-2 cursor-pointer" />
          </div>
          <div
            className="grow w-12 flex flex-col justify-center gap-2 border-r-2 border-cgray-300 h-full max-h-screen overflow-y-scroll overflow-x-clip cursor-pointer"
          >
            <div className='-rotate-90 whitespace-nowrap uppercase'>{t('Favorite Classes')}</div>
          </div>
        </div>
      }
      {!!features?.length &&
        isFeaturesOpen ?
        <div className='flex flex-col'>
          <div
            className="sticky top-0 z-20 flex items-center bg-cblue-100 border-t-2 border-b-2 border-cblue-500 pt-3 pb-3 mb-3 font-bold text-sm text-cgray-600"
          >
            <IconArrowBarToLeft className="w-6 h-6 ml-4 cursor-pointer" onClick={() => setIsFeaturesOpen(false)} />
            <div className="w-36 mt-0.5 mb-0.5 h-5 ml-2">{t('Default features')}</div>
          </div>
          <FilterFeatures
            isLoading={searchRecordsLoading}
            features={features}
          />
        </div>
        : !!features?.length &&
        <div onClick={() => setIsFeaturesOpen(true)}>
          <div className="sticky top-0 z-20 flex items-center bg-cblue-100 border-t-2 border-b-2 border-cblue-500 pt-3 pb-3 mb-3 font-bold text-sm text-cgray-600">
            <IconArrowBarRight className="w-6 h-6 ml-2 cursor-pointer" />
          </div>
          <div
            className="grow w-12 flex flex-col justify-center gap-2 border-r-2 border-cgray-300 h-full max-h-screen overflow-y-scroll overflow-x-clip cursor-pointer"
          >
            <div className='-rotate-90 whitespace-nowrap uppercase'>{t('Default features')}</div>
          </div>
        </div>
      }
    </>
  );
}
