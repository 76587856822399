import { gql } from '../../../__generated__/gql';

export const ADD_GROUP_ETIM_CLASS = gql(`
  mutation addGroupEtimClass(
    $etimClass: ID!
    $group: ID!
    $quotation: ID!
  ){
    addGroupEtimClass(
      input: {
        etimClass: $etimClass
        group: $group
        quotation: $quotation
      }
    ){ 
      response
      errors{
        field
        messages
      }
    }
  }
`);


export const GROUP_ETIM_FEATURE = gql(`
  mutation groupFeature(
    $etimFeature: ID!
    $group: ID!
    $value: String! 
    $id: ID
  ){
    groupFeature(
      input: {
        etimFeature: $etimFeature
        group: $group
        value: $value
        id: $id
      }
    ){ 
      response{
        id
        etimFeature {
					code
					featureType
					localizedDescription
				}
				value
      }
      errors{
        field
        messages
      }
    }
  }
`);

export const DELETE_GROUP_ETIM_FEATURE = gql(`
  mutation deleteGroupFeature(
    $groupFeature: ID!
  ){
    deleteGroupFeature(
      input: {
        groupFeature: $groupFeature
      }
    ){ 
      response 
      errors{
        field
        messages
      }
    }
  }
`);

export const UPDATE_GROUP_POSITION_TYPE = gql(`
  mutation updateGroupPositionType(
    $positionType: String
    $group: ID!
    $quotation: ID!
  ){
    updateGroupPositionType(
      input: {
        positionType: $positionType
        group: $group
        quotation: $quotation
      }
    ){ 
      response
      errors{
        field
        messages
      }
    }
  }
`);


export const CREATE_QUOTATION_GROUP_MUTATION = gql(`
mutation createQuotationGroup(
  $description: String!
  $order: Int!
  $quotation: ID!
  $parent: ID
  ){
    createQuotationGroup(
      input: {
        description: $description
        order: $order
        quotation: $quotation
        parent: $parent
      }
    )
  { 
    response
    errors{
      field
      messages
    }
  }
}
`);

export const UPDATE_GROUP_NOTES = gql(`
  mutation updateGroupNotes(
    $quotation: ID!,
		$notes: String!,
		$group: ID!,
    ){
      updateGroupNotes(
        input: {
          quotation: $quotation,
          notes: $notes,
          group: $group,
        }
      )
    { 
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const UPDATE_GROUP_DESCRIPTION = gql(`
  mutation updateGroupDescription(
    $quotation: ID!
    $group: ID!
    $description: String!
  ){
    updateGroupDescription(
      input: {
        quotation: $quotation
        group: $group
        description: $description
      }
    ){
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const UPDATE_GROUP_DISCOUNT = gql(`
  mutation updateGroupDiscount(
    $quotation: ID!,
    $discount: Decimal!
		$group: ID!,
    ){
      updateGroupDiscount(
        input: {
          quotation: $quotation,
          discount: $discount,
          group: $group,
        }
      )
    { 
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const UPDATE_GROUP_PRODUCTION_UNIT = gql(`
  mutation updateGroupProductionUnit(
    $quotation: ID!
    $group: ID!
    $isProductionUnit: Boolean!
  ){
    updateGroupProductionUnit(
      input: {
        quotation: $quotation
        group: $group
        isProductionUnit: $isProductionUnit
      }
    ){
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const UPDATE_GROUP_PRODUCTION_UNIT_STATUS_MUTATION = gql(`
  mutation updateGroupProductionUnitStatus(
    $quotation: ID!
    $group: ID!
    $productionUnitStatus: ID!
  ){
    updateGroupProductionUnitStatus(
      input: {
        quotation: $quotation
        group: $group
        productionUnitStatus: $productionUnitStatus
      }
    ){
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const TOGGLE_GROUP_MANUAL_PRICE = gql(`
  mutation toggleGroupManualSalesPrice(
    $group: ID!
  ){
    toggleGroupManualSalesPrice(
      input: {
        group: $group
      }
    ){
      response
      errors{
        field
        messages
      }
    }
  }
`);


export const UPDATE_GROUP_MANUAL_SALES_PRICE_MUTATION = gql(`
  mutation updateGroupManualSalesPrice(
    $priceType: String!
    $groupManualSalesPricePiece: ID!
    $value: Decimal!
  ){
    updateGroupManualSalesPrice(
      input: {
        priceType: $priceType
        groupManualSalesPricePiece: $groupManualSalesPricePiece
        value: $value
      }
    ){
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const TOGGLE_QUOTATION_GROUP_FAVOURITE_STATUS = gql(`
  mutation toggleQutationGroupFavouriteStatus(
    $group: ID!
  ) 
  {
    toggleQuotationGroupFavouriteStatus(
      input: {
        group: $group
      }
    ) 
    {
      response
      errors{
        field
        messages
      }
    }
  }
`);
