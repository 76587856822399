/* eslint-disable @typescript-eslint/no-shadow */
import { useMutation } from '@apollo/client';
import type {
  Announcements,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragStartEvent,
  Modifier,
  UniqueIdentifier,
} from '@dnd-kit/core';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useParams } from 'react-router-dom';
import { ItemTypeChoices } from '../../../__generated__/graphql';
import { MOVE_QUOTATION_ITEM_MUTATION } from '../../../api/mutations/quotations/item';
import { QUOTATION_QUERY } from '../../../api/queries/quotations/quotation';
import { MaxUncollapsedDepthSource } from '../../../constants';
import { sortableTreeKeyboardCoordinates } from '../../../helpers/dnd/keyboardCoordinates';
import {
  buildTree,
  findDeepestUncollapsedLevel,
  findItemDeep,
  flattenTree,
  getChildCount,
  getProjection,
  removeChildrenOf,
} from '../../../helpers/dnd/utilities';
import { useAppDispatch } from '../../../helpers/reduxHooks';
import { classNames } from '../../../helpers/utils';
import { setIsLoadingUpdate, setMaximumQuotationUncollapsedDepth } from '../../../redux/quotationSlice';
import { dropAnimationConfig } from '../../shared/dnd/config';
import type { FlattenedItem, SensorContext } from '../../shared/dnd/types';
import { QuotationListItem } from './row/QuotationListItem';

const adjustTranslate: Modifier = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25,
  };
};

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

interface Props {
  defaultItems: FlattenedItem[];
  indentationWidth: number;
  onCollapse?(id: UniqueIdentifier): void;
  quotationLength: number;
  initialIndex: number;
}

export default function QuotationListDndItem(props: Props) {
  const {
    indentationWidth, defaultItems, onCollapse, quotationLength, initialIndex,
  } = props;
  const [items, setItems] = useState(defaultItems);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [currentPosition, setCurrentPosition] = useState<{
    parentId: UniqueIdentifier | null;
    overId: UniqueIdentifier;
  } | null>(null);
  const { quotationId } = useParams();
  const dispatch = useAppDispatch();
  const [previousParentId, setPreviousParentId] = useState<UniqueIdentifier | null | undefined>(null);

  const [
    moveMutation,
    {
      loading: moveMutationLoading,
    },
  ] = useMutation(MOVE_QUOTATION_ITEM_MUTATION, {
    refetchQueries:[
      { query:QUOTATION_QUERY,
        variables: {
          quotation: quotationId,
        }, 
      },
    ],
  },
  );

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items);
    const collapsedItems = flattenedTree.reduce<any>(
      (acc, { children, collapsed, id }) =>
        collapsed && children.length ? [...acc, id] : acc,
      [],
    );

    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems,
    );
  }, [activeId, items]);

  const projected =
    activeId && overId
      ? getProjection(
        flattenedItems,
        activeId,
        overId,
        offsetLeft,
        indentationWidth,
      )
      : null;
  const sensorContext: SensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });
  const [coordinateGetter] = useState(() =>
    sortableTreeKeyboardCoordinates(sensorContext, true, indentationWidth),
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    }),
  );

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [
    flattenedItems,
  ]);
  const activeItem = activeId
    ? flattenedItems.find(({ id }) => id === activeId)
    : null;

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };

    const deepestUncollapsedLevel = findDeepestUncollapsedLevel(flattenedItems);
    dispatch(setMaximumQuotationUncollapsedDepth({ 
      maxDepth: deepestUncollapsedLevel, 
      source: MaxUncollapsedDepthSource.QuotationListDndItem, 
    }));

  }, [flattenedItems, offsetLeft, dispatch]);

  useEffect(() => {
    // Update local items each time we have updated items incoming outside this component.
    setItems(defaultItems);
  }, [defaultItems]);

  useEffect(() => {
    dispatch(setIsLoadingUpdate(moveMutationLoading));
  }, [dispatch, moveMutationLoading]);

  const announcements: Announcements = {
    onDragStart({ active }) {
      return `Picked up ${active.id}.`;
    },
    onDragMove({ active, over }) {
      return getMovementAnnouncement('onDragMove', active.id, over?.id);
    },
    onDragOver({ active, over }) {
      return getMovementAnnouncement('onDragOver', active.id, over?.id);
    },
    onDragEnd({ active, over }) {
      return getMovementAnnouncement('onDragEnd', active.id, over?.id);
    },
    onDragCancel({ active }) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`;
    },
  };

  function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
    setActiveId(activeId);
    setOverId(activeId);
    const activeItem = flattenedItems.find(({ id }) => id === activeId);
    setPreviousParentId(activeItem?.parentId);
    
    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: activeId,
      });
    }

    document.body.style.setProperty('cursor', 'grabbing');
  }

  function handleDragMove({ delta }: DragMoveEvent) {
    setOffsetLeft(delta.x);
  }

  function handleDragOver({ over }: DragOverEvent) {
    setOverId(over?.id ?? null);
  }

  function handleDragEnd({ active, over }: DragEndEvent) {
    resetState();

    if (projected && over) {
      const { depth, parentId } = projected;
      const clonedItems: FlattenedItem[] = JSON.parse(
        JSON.stringify(flattenTree(items)),
      );
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];

      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
      const newItems = buildTree(sortedItems);
      //This item will be used to send update event to Back End.
      const updatedItem = findItemDeep(newItems, activeTreeItem.id) as FlattenedItem;
      
      // products and services can not be on the root level
      if (!updatedItem?.parentId && updatedItem.itemType !== ItemTypeChoices.Group) {
        return;
      }
       
      // This "if" condition prevents redundant moveMutation, performed at the the drag end,
      // if the item's end position matches the start position 
      // and it is not movement inside group (with the same index but different parents)
      if (activeIndex === overIndex && previousParentId === updatedItem?.parentId) {        
        return;
      }
      
      moveMutation(
        {
          variables: {
            quotation: quotationId as string,
            items: [updatedItem?.id as string],
            parent: updatedItem?.parentId as string,
            order: updatedItem?.index as number,
          },
        },
      );
    }
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);

    document.body.style.setProperty('cursor', '');
  }

  function getMovementAnnouncement(
    eventName: string,
    activeId: UniqueIdentifier,
    overId?: UniqueIdentifier,
  ) {
    if (overId && projected) {
      if (eventName !== 'onDragEnd') {
        if (
          currentPosition &&
          projected.parentId === currentPosition.parentId &&
          overId === currentPosition.overId
        ) {
          return;
        } else {
          setCurrentPosition({
            parentId: projected.parentId,
            overId,
          });
        }
      }

      const clonedItems: FlattenedItem[] = JSON.parse(
        JSON.stringify(flattenTree(items)),
      );
      const overIndex = clonedItems.findIndex(({ id }) => id === overId);
      const activeIndex = clonedItems.findIndex(({ id }) => id === activeId);
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const previousItem = sortedItems[overIndex - 1];
      const nextItem = sortedItems[overIndex + 1];

      let announcement;
      const movedVerb = eventName === 'onDragEnd' ? 'dropped' : 'moved';
      const nestedVerb = eventName === 'onDragEnd' ? 'dropped' : 'nested';

      // This "if" condition prevents error in case if quotation have only one Group item without nested Products
      // and you drag it and drop it
      if (!previousItem && !nextItem) {
        return;
      }

      if (!previousItem) {
        announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`;
      } else {
        if (projected.depth > previousItem.depth) {
          announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`;
        } else {
          let previousSibling: FlattenedItem | undefined = previousItem;
          while (previousSibling && projected.depth < previousSibling.depth) {
            const parentId: UniqueIdentifier | null = previousSibling.parentId;
            previousSibling = sortedItems.find(({ id }) => id === parentId);
          }

          if (previousSibling) {
            announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`;
          }
        }
      }

      return announcement;
    }

    return;
  }

  return (
    <DndContext
      accessibility={{ announcements }}
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        {flattenedItems.map((flattenedItem, index) => {
          const isRootItem = !flattenedItem.parentId;
          const isLastItem = flattenedItems.length - 1 === index;

          return (
            <div
              key={flattenedItem.id}
              className={classNames(
                isRootItem && 'top-[139px] z-20 mt-2 border-t rounded-t-md',
                isLastItem && 'mb-2 border-b-2 rounded-b-md',
                ' border-l border-cgray-300',
              )}
            >
              <QuotationListItem
                key={flattenedItem.id}
                id={flattenedItem.id}
                item={flattenedItem}
                depth={flattenedItem.id === activeId && projected ? projected.depth : flattenedItem.depth}
                indentationWidth={indentationWidth}
                indicator
                collapsed={Boolean(flattenedItem.collapsed && flattenedItem.children.length)}
                onCollapse={onCollapse && flattenedItem.children.length ? ()=>onCollapse(flattenedItem.id) : undefined}
                hasDndContext
                quotationLength={quotationLength}
                initialIndex={initialIndex}
              />
            </div>
          );
        })}
        {createPortal(
          <DragOverlay
            dropAnimation={dropAnimationConfig}
            modifiers={[adjustTranslate]}
          >
            {activeId && activeItem ? (
              <QuotationListItem
                id={activeId}
                depth={activeItem.depth}
                clone
                childCount={getChildCount(items, activeId) + 1}
                item={activeItem}
                indentationWidth={indentationWidth}
                quotationLength={quotationLength}
                initialIndex={initialIndex}
              />
            ) : null}
          </DragOverlay>,
          document.body,
        )}
      </SortableContext>
    </DndContext>
  );
}
