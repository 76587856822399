/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import type { GroupBase } from 'react-select';
import type { LoadOptions } from 'react-select-async-paginate';
import { PROJECT_QUERY } from '../../../api/queries/projects';
import { AsyncSelectInput } from '../../../layout/fields';

interface Props {
  onChange: (selectedId: string) => void,
  projectId?: string,
  isDisabled?: boolean,
  loadOptions: LoadOptions<unknown, GroupBase<unknown>, { skip: any }>,
  isClearable?: boolean
}

export default function SelectProject(props: Props) {
  const { onChange, projectId, isDisabled, loadOptions, isClearable } = props;
  const { t } = useTranslation();

  const { 
    data: projectData,
  } = useQuery(PROJECT_QUERY, {
    variables: { 
      project: projectId as string,
    },
    skip: !projectId,
  });

  let selectedProject: SelectOption | undefined;
  if (projectData?.project?.response) {
    selectedProject = { 
      value: projectData?.project?.response?.id,
      label: projectData?.project?.response?.nameLabel as string,
    };
  }

  return (
    <AsyncSelectInput
      value={selectedProject && selectedProject}
      placeholder={t('Search by Project')}
      onChange={(e) => onChange(e?.value ? e.value as string : '')}
      isDisabled={isDisabled}
      loadOptions={loadOptions}
      isClearable={isClearable}
    />
  );
}
