const de = {
  translation: {
    'Log out': 'Ausloggen',
    'Feedback': 'Feedback De',
    'Dashboard': 'Projektauswahl',
    'Project Name': 'Projekt Name',
    'Only integers are allowed': 'Nur ganze Zahlen sind erlaubt',
    'Only Groups are allowed to be pasted at the root level': 'Nur Gruppen können im Root Level des Angebotes erstellt werden',
    'Notes': 'Anmerkungen',
    'Cancel': 'Abbrechen',
    'Save': 'Speichern',
    'Project External Id': 'Ext. Projektnummer',
    'Example: 123456': 'Beispiel: 123456',
    "project was successfully created. You will be redirected to newly created project shortly and will be able to add products to it's quotation": 'Projekt wurde erfolgreich erstellt. Sie werden in Kürze zum neu erstellten Projekt weitergeleitet und können Produkte zu dessen Angebot hinzufügen',
    'Project connects customer and quotation data.': 'Das Projekt verbindet Kunden und Angebotsdaten',
    'Project': 'Projekt',
    'Select Customer': 'Wähle Kunden',
    'Service Wage': 'Dienstleistungs Lohn',
    'Select Service Wage': 'Wähle Lohngruppe Werkstatt',
    'Select Sales Wage': 'Wähle Lohngruppe Vertrieb',
    'Sales Wage': 'Sales Wage De',
    'Create Group Template': 'Gruppenvorlage erstellen',
    'Paste below': 'Unten einfügen',
    'New group was successfully created': 'Neue Gruppe wurde erfolgreich erstellt',
    'New service was successfully created': 'Neue Dienstleistung wurde erfolgreich erstellt',
    'Group Template was successfully created': 'Gruppen Template wurde erfolgreich erstellt',
    'Group description': 'Beschreibung Gruppe',
    'Service description': 'Beschreibung Dienstleistung',
    'Requried': 'erforderlich',
    'The maximum number of Project External ID cannot exceed 2.147.483.647': 'Die maximale Anzahl externer Projekt-IDs darf 2.147.483.647 nicht überschreiten',
    'The number of Project External ID cannot be negative or equal 0': 'Die Anzahl der externen Projekt-IDs darf nicht negativ oder gleich 0 sein',
    'Project External ID must contain only digits': 'Die externe Projekt-ID darf nur Ziffern enthalten',
    'Description': 'Beschreibung',
    'Position': 'Position',
    'Qty': 'Anzahl',
    'Product ID': 'Produkt ID',
    'Supplier': 'Lieferant',
    'Wage': 'Lohn',
    'Time': 'Zeit',
    'Purchase Price per Piece': 'Einkaufspreis/Stück',
    'PP/Piece': 'EK/Stück',
    'Sales Price per Piece': 'Verkaufspreis/Stück',
    'SP/Piece': 'VK/Stück',
    'Net Sales Price': 'Netto VK',
    'Profit per Piece': 'Profit pro Stück',
    'Total Sales Price': 'Verkaufspreis Total',
    'Total SP': 'VK Total',
    'Total Profit': 'Profit Total',
    'Datanorm': 'Datanorm',
    'Type': 'Typ',
    'GTIN': 'GTIN',
    'Notes': 'Anmerkungen',
    'Price': 'Preis',
    'Match code': 'Match code',
    'Price indicator': 'Preis indikator',
    'Units per price': 'Einheiten pro Preis',
    'Order amount': 'Bestellmenge',
    'Price/Piece': 'Preis/Stück',
    'Unit': 'Einheit',
    'Group Discount': 'Gruppen Rabatt',
    'Discount': 'Rabatt',
    'Discounted Price/Piece': 'rabattierter Preis/Stück',
    'Raw Material Surcharge': 'Rohstoffzuschlag',
    'ETIM': 'ETIM',
    'Short Description': 'Kurzbeschreibung',
    'Long Description': 'Langbeschreibung',
    'ETIM Group': 'ETIM Gruppe',
    'ETIM Class': 'ETIM Klasse',
    'Specific Surcharge': 'Spezifischer Zuschlag',
    'Base Sales Price/Piece': 'Basis VK/Stück',
    'Material Cost Burden Rate': 'Material-Gemeinkosten-Zuschlag',
    'Material Overhead': 'Materialgemeinkosten',
    'Material Costs': 'Materialkosten',
    'Manufacturing Time': 'Fertigungs-Zeit',
    'Sales Time': 'Vertriebs-Zeit',
    'Manufacturing Labour Cost': 'Fertigungslohnkosten',
    'Manufacturing Overhead Rate': 'Fertigungsgemeinkosten-Zuschlag',
    'Manufacturing Overhead': 'Fertigungsgemeinkosten',
    'Production Cost': 'Fertigungskosten',
    'Manufacturing Cost': 'Fertigungskosten',
    'Sales Overhead Rate': 'Fertigungsgemeinkosten-Zuschlag',
    'Sales Overhead': 'Vertriebsgemeinkosten-Zuschlag',
    'Special Direct Costs Sales': 'Spezielle Vertriebskosten',
    'Shipping Cost': 'Versandkosten',
    'Sales Cost': 'Vertriebskosten',
    'Administrative Overhead Rate': 'Verwaltungsgemeinkosten-Zuschlag',
    'Administrative Costs': 'Verwaltungskosten',
    'Cost Administration Sales': 'Kosten Vertrieb und Verwaltung',
    'Cost Per Item': 'Kosten pro Artikel',
    'costs per unit': 'Kosten pro Einheit',
    'Profit Surcharge': 'Gewinnzuschlag',
    'Profit': 'Gewinn',
    'Discount': 'Rabatt',
    'Show Suggestions': 'Zeige Vorschläge',
    'Open options': 'Öffne Menü',
    'Hide details': 'Details ausblenden',
    'Show details': 'Details einblenden',
    'Create Group': 'Create Group De',
    'Create Group Below': 'Gruppen unterhalb anfügen',
    'Create Group Inside': 'Gruppe innerhalb erstellen',
    'Create Service': 'Dienstleitung erstellen',
    'Create Service Below': 'Dienstleistung unten anfügen',
    'Create Service Inside': 'Dienstleistung innerhalb erstellen',
    'Add Item': 'Artikel hinzufügen',
    'Add Item Below': 'Artikel unten anfügen',
    'Add Item Inside': 'Artikel innerhalb erstellen',
    'Copy Item': 'Kopiere Artikel',
    'Clear selection': 'Auswahl aufheben',
    'Remove Item': 'Artikel entfernen',
    'There are no Groups at the moment. Click here to create a Group.': 'Bisher gibt es keine Gruppen. Klicken Sie hier, um eine Gruppe zu erstellen',
    'Create New Group': 'Create New Group De',
    'Create New Service': 'Create New Service De',
    'This action will create new group in current Quotation': 'Diese Handlung erstellt eine neue Gruppe im aktuellen Angebot',
    'This action will create new service item in the current Quotation': 'Diese Handlung erstellt eine neue Dienstleistung im aktuellen Angebot',
    'This action will create new service item inside current Group': 'Diese Handlung erstellt eine neue Dienstleistung in der ausgewählten Gruppe',
    'Create New Quotation': 'Neue Gruppe erstellen',
    'This Action will create the copy of current Quotation': 'Durch diese Aktion wird eine neue Gruppe im aktuellen Angebot erstellt',
    'Create': 'anlegen',
    'Item was successfully deleted': 'Artikel wurde erfolgreich gelöscht',
    'This action will delete': 'Durch diese Aktion wird gelöscht',
    'item': 'Artikel',
    'Delete': 'Löschen',
    'The following group template(s) can be used instead of the current group:': 'Die folgenden Gruppenvorlagen können anstelle der aktuellen Gruppe verwendet werden',
    'Group Templates Suggestions': 'Vorschläge für Gruppenvorlagen',
    'Use': 'verwenden',
    'SubTotal': 'Zwischensumme',
    'Total': 'Total',
    'Quotation Total': 'Angebots- Zusammenfassung',
    'Create copy of current Quotation': 'Erstellen Sie eine Kopie des aktuellen Angebots',
    'Add Multiple Products': 'Mehrere Produkte hinzufügen',
    'Create Group at the Bottom': 'Erstellen Sie unten eine Gruppe',
    'Reset Items Positions': 'Artikelpositionen zurücksetzen',
    'Generate PDF version': 'PDF Version erstellen',
    'Quotation': 'Angebot',
    'Project': 'Projekt',
    'Estimate': 'Rechen',
    'Drag and Drop mode': 'Drag and Drop mode De',
    'Release version': 'release version',
    'Search Class': 'Suchklasse',
    'Yes': 'Ja',
    'No': 'Nein',
    'Groups': 'Gruppen',
    'To activate the Search, please select Group and Class': 'Um die Suche zu aktivieren, wählen Sie bitte Gruppe und Klasse aus',
    'Search': 'Suche',
    'Select records to unblock Paste Mode': 'Wählen Sie Datensätze aus, um den Einfügemodus zu entsperren',
    'Paste mode': 'Einfügemodus',
    'Search by': 'Suche nach',
    'ETIM Standard': 'ETIM Standard',
    'Quotation': 'Angebot',
    'Open options': 'Optionen',
    'Show details': 'Details anzeigen',
    'Hide details': 'Details ausblenden',
    'Remove': 'Entfernen',
    'Select': 'Auswählen',
    'No results found': 'Keine Ergebnisse gefunden ',
    'Search results will be displayed here': 'Such-Ergebnisse werden hier angezeigt',
    'Please hit Enter to start Search': 'Bitte drücken sie Enter um die Suche zu starten',
    'There are no records at the moment.': 'Es können im Moment keine Ergebnisse angezeigt werden',
    'Drag records here to attach them to this Quotation.': 'Bitte ziehen sie die Ergbebnisse hier herüber um sie dem Angebot anzufügen',
    'Add to selected Quotation': 'dem ausgewählten Angebot anfügen',
    'Previous': 'Vorheriges',
    'Next': 'Nächstes',
    'Email is Required': 'Email benötigt',
    'Password is Required': 'Passwort benötigt',
    'E-mail': 'E-mail',
    'Password': 'Passwort',
    'Log in': 'Log in',
    'Error: Bad Request.': 'Fehler: Ungültige Anfrage',
    'Error: Unauthorized.': 'Fehler: Nicht autorisiert',
    'Error: Forbidden.': 'Fehler: Verboten',
    'Error: Not Found.': 'Fehler: nicht gefunden',
    'Internal Server Error.': 'Interner Server Fehler',
    'Error: Bad Gateway.': 'Fehler: Schlechtes Gateway',
    'Please Select the Quotation Project': 'Bitte wählen Sie das Angebotsprojekt aus',
    'Create New Project': 'Neues Projekt anlegen',
    'Pages': 'Menüleiste',
    'Menu': 'Menü',
    'User': 'Benutzer',
    'quotation': 'Angebot',
    'quotations': 'Angebote',
    'Represents Group Template': 'Stellt eine Gruppen Vorlage dar',
    'Features': 'Eigenschaften',
    'Service Time': 'Service Time De',
    'It is forbidden to add services to the root level': 'Es ist nicht zulässig auf dieser Ebene Dienstleistungen anzufügen',
    'Deselect All': 'Alle abwählen',
    'Select All': 'Alle wählen',
    'Manufacturing time, per item': 'Montagezeit pro Stück',
    'Manufacturing wage': 'Manufacturing wage De',
    'Service time, per item': 'Dienstleistungszeit pro Stück',
    'Sum of nested time values, excluding Sales time': 'Summe der untergeordneten Zeitwerte ohne Vertriebszeiten',
    'Required': 'Erforderlich',
    'Number must be positive': 'Die Zahl muss positiv sein',
    'Selected catalogs': 'Ausgewählte Kataloge',
    'Shipping cost': 'Shipping cost DE',
    'obsolete value': 'veralteter Wert',
    'Service Labour Cost': 'Service Labour Cost DE',
    'Net Sales Price/Piece': 'Net Sales Price/Piece De',
    'Net Sales Price/Piece including discount': 'Net Sales Price/Piece including discount DE',
    'Special Price': 'Special Price DE',
    'Select Project Type': 'Select Project Type DE',
    'Only Groups can be added to the root level': 'Der Stammebene können nur Gruppen hinzugefügt werden',
    'No features selected yet': 'Keine Merkmale ausgewählt',
    'Not applicable': 'Unzutreffend',
    'Regenerate suggestion':'Vorschlag neu generieren',
    'Suggested Price per Piece': 'Empfohlener Preis pro Stück',
    'for': 'für',
    'Product Price Suggestion was updated':'Der Produktpreisvorschlag wurde aktualisiert',
    'Info': 'Info DE',
    'Icons legend': 'Icons legend DE',
    'Group Item. Can be placed at the root level of Quotation. Any Item type can be placed inside the Group.': 'Gruppenelement. Kann auf der Stammebene des Angebots platziert werden. Jeder Elementtyp kann innerhalb der Gruppe platziert werden.',
    'This Group represents Group Template.': 'Diese Gruppe repräsentiert ein Gruppen- Template',
    'This Group has Template presets to apply.': 'Diese Gruppe hat verschiedene Gruppenteplates die ausgewählt werden können',
    'Product item. Represents products from ETIM catalogs. Can only be placed inside Groups.': 'Produktartikel. Stellt Produkte aus ETIM-Katalogen dar. Kann nur innerhalb von Gruppen platziert werden.',
    'Service Item. Represents on-site services. Can only be placed inside Groups.': 'Dienstleistungsposition Stellt Dienstleistungen aus ETIM-Katalogen dar. Kann nur innerhalb von Gruppen platziert werden.',
    'Items drag handle.': 'Artikel Platzierungsindikator',
    'Show suggestions based on Group Templates stored in database.': 'Bitte Vorschläge von Gruppen-Templates aus der Datenbank anzeigen',
    'Create Group Template from selected Group.': 'Aus der ausgewählten Gruppe ein Gruppen TEmplate erstellen',
    'List of all available keyboard shortcuts': 'Liste aller verfügbaren keyboard shortcuts',
    'Collapse Group': 'Gruppe einklappen',
    'Uncollapse Group': 'Gruppe ausklappen',
    'Move down': 'Nach unten bewegen',
    'Move up': 'Nach oben bewegen',
    'Show details': 'Details anzeigen',
    'Close details': 'Details ausblenden',
    'Create Group Below': 'Gruppe unterhalb erstellen',
    'Create Group Inside': 'Gruppe innerhalb erstellen',
    'Create Service Below': 'Dienstleistung unterhalb erstellen',
    'Create Service Inside': 'Dienstleistung innerhalb erstellen',
    'Add Item Below': 'Artikel unterhalb erstellen',
    'Add Item Inside': 'Artikel innerhalb erstellen',
    'Copy Item': 'kopiere Artikel',
    'Cut Item': 'Artikel ausschneiden',
    'Paste Item': 'Pplatziere Artikel',
    'Clear selection': 'Auswahl entfernen',
    'Delete Item': 'Lösche Artikel',
    'Field was successfully updated': 'Das Feld wurde erfolgreich aktualisiert',
    'Item was successfully pasted': 'Das Element wurde erfolgreich eingefügt',
    'Position should contain only positive numbers and dots': 'Die Position sollte nur positive Zahlen und Punkte enthalten',
    'Quotation not found': 'Zitat nicht gefunden',
    'Items positions generation disabled': 'Generierung von Artikelpositionen deaktiviert',
    'Street': 'Straße',
    'City': 'Stadt',
    'Country': 'Land',
    'Zip code': 'Postleitzahl',
    'Project details:': 'Projekt Details:',
    'Project address:': 'Projektadresse:',
    'Equipment installation address.': 'Adresse der Geräteinstallation.',
    'Zip code must be no more than 6 characters': 'Die Postleitzahl darf nicht mehr als 6 Zeichen lang sein',
    'Create New Item': 'Neues Element erstellen',
    'Quantity must contain only digits': 'Die Menge darf nur Ziffern enthalten',
    'New product was successfully created': 'Neues Produkt wurde erfolgreich erstellt',
    'quantity': 'menge',
    'Quantity': 'Menge',
    'price': 'preis',
    'Please fill out all fields first and have a look at available options': 'Bitte füllen Sie zunächst alle Felder aus und sehen Sie sich die verfügbaren Optionen an',
    'Create New Product': 'Neues Produkt erstellen',
    'Create Product Below': 'Erstellen Sie unten ein Produkt',
    'Create Product Inside': 'Erstellen Sie ein Produkt im Inneren',
    'The following item(s) may be used:': 'Folgende Artikel können verwendet werden:',
    'Suggestions will be displayed here': 'Vorschläge werden hier angezeigt',
    'Use instead': 'Verwenden',  
    'Save': 'Speichern',
    'Compose mode is disabled for ReadOnly quotations': 'Der Verfassen-Modus ist für schreibgeschützte Zitate deaktiviert',
    'Compose': 'Verfassen',
    'Favorite Classes': 'Lieblingsklassen',
    'Default features': 'Standardfunktionen',
    'Edit Feature': 'Funktion bearbeiten',
    'Add Feature': 'Funktion hinzufügen',
    'Select Class': 'Wählen Sie Klasse aus',
    'Select Feature': 'Wählen Sie Funktion aus',
    'Select feature for current Group': 'Wählen Sie die Funktion für die aktuelle Gruppe aus',
    'Select Value': 'Wähle Wert',
    'Value': 'Wert',
    'Delete Feature': 'Funktion löschen',
    'This action will delete feature': 'Durch diese Aktion wird die Funktion gelöscht',
    'The minimum value must be less or equal to the maximum value': 'Der Mindestwert muss kleiner oder gleich dem Maximalwert sein',
    'Service time': 'Servicezeit',
    'Feature was successfully updated': 'Die Funktion wurde erfolgreich aktualisiert',
    'New feature was added': 'Neue Funktion wurde hinzugefügt',
    'Select item in Quotation': 'Wählen Sie den Artikel im Angebot aus',
    'Product/Service cannot be pasted on the root level': 'Das Produkt/die Dienstleistung kann nicht auf der Stammebene eingefügt werden',
    'Paste to the selected Quotation': 'In das ausgewählte Angebot einfügen',
    'Products': 'Produkte',
    'Groups': 'Gruppen',
    'All': 'Alle',
    'Search for': 'Suchen nach',
    'Move Group Down': 'Sich abwärts bewegen',
    'Move Group Up': 'Nach oben bewegen',
    'The maximum number cannot exceed 2.147.483.647': 'Die maximale Anzahl darf 2.147.483.647 nicht überschreiten.',
    'The number cannot be negative or equal 0': 'Die Zahl darf nicht negativ oder gleich 0 sein',
    'Days of validity must contain only digits': 'Die Gültigkeitstage dürfen nur Ziffern enthalten',
    'Days of validity': 'Gültigkeitstage',
    'All nested groups are selected automatically if parent group is selected': 'Alle verschachtelten Gruppen werden automatisch ausgewählt, wenn die übergeordnete Gruppe ausgewählt ist',
    'Basic Price and Quantity': 'Grundpreis und Menge',
    'Price and quantity at the Root Level, quantity at Level 1, and description at Level 2': 'Preis und Menge auf der Stammebene, Menge auf Ebene 1 und Beschreibung auf Ebene 2',
    'Standard Price and Quantity': 'Standardpreis und Menge',
    'Price and quantity at the Root Level and Level 1, quantity at Level 2, and description at Level 3': 'Preis und Menge auf der Stammebene und Ebene 1, Menge auf Ebene 2 und Beschreibung auf Ebene 3',
    'Advanced Price and Quantity': 'Erweiterter Preis und Menge',
    'Price and quantity at the Root Level, Level 1, and Level 2, quantity at Level 3, and description at Level 4': 'Preis und Menge auf der Stammebene, Ebene 1 und Ebene 2, Menge auf Ebene 3 und Beschreibung auf Ebene 4',
    'Comprehensive Price and Quantity': 'Umfassender Preis und Menge',
    'Price and quantity at the Root Level, Level 1, Level 2, and Level 3, quantity at Level 4, and description at Level 5': 'Preis und Menge auf der Stammebene, Ebene 1, Ebene 2 und Ebene 3, Menge auf Ebene 4 und Beschreibung auf Ebene 5',
    'Select groups': 'Gruppen auswählen',
    'Select layout': 'Layout auswählen',
    'Edit text': 'Text bearbeiten',
    'Generating...': 'Erstellen...',
    'Client extension': 'Client-Erweiterung',
    'Rejected': 'Abgelehnt',
    'Production': 'Produktion',
    'Draft': 'Entwurf',
    'Completed': 'Abgeschlossen',
    'Awaiting approval': 'Wartet auf Genehmigung',
    'Approved': 'Genehmigt',
    'Add your note here': 'Fügen Sie hier Ihre Notiz hinzu',
    'Export as Electronic Sheet': 'Als elektronisches Blatt exportieren',
    'Project Status': 'Projekt-Status',
    'Project Log': 'Projektprotokoll',
    'Delete this Note?': 'Diese Notiz löschen?',
    'Select managers for this project': 'Manager für dieses Projekt auswählen',
    'Is Production Unit': 'Ist Produktionseinheit',
    'Select ETIM Class': 'ETIM-Klasse auswählen',
    'General': 'Gebräuchlich',
    'Address was successfully updated': 'Adresse wurde erfolgreich aktualisiert',
    'Bill of Quantities': 'Leistungsverzeichnis',
    'Direct sale': 'Direktverkauf',
    'Edit Project Address': 'Projektadresse bearbeiten',
    'Edit Project Details': 'Projektdetails bearbeiten',
    'Project was successfully updated': 'Projekt wurde erfolgreich aktualisiert',
    'Project managers': 'Projektmanager',
    'Edit project': 'Projekt bearbeiten',
    'Production Unit Status': 'Status der Produktionseinheit',
    'Is Production Unit?': 'Ist es eine Produktionseinheit?',
    'No Statuses for Production Units available.': 'Keine Status für Produktionseinheiten verfügbar.',
    'Please contact your administrator.': 'Bitte wenden Sie sich an Ihren Administrator.',
    'Can not be changed for readonly quotation': 'Kann für schreibgeschützte Zitate nicht geändert werden',
    'Production Unit Status can be changed only if Project Status is "Production"': 'Der Status der Produktionseinheit kann nur geändert werden, wenn der Projektstatus „Produktion“ ist.',
    'Disabled for search by Quotation': 'Für die Suche nach Zitat deaktiviert',
    'ETIM Catalog': 'ETIM Katalog',
    'Manual': 'Manuell',
    'Absolute': 'Absolut',
    'Percentage': 'Prozentsatz',
    'Manual Price': 'Manueller Preis',
    'Set manual Price?': 'Manuellen Preis festlegen?',
    'Price type': 'Preistyp',
    'Price Value': 'Preiswert',
    'This group (or any of nested groups) has percentage type of manual price. This price is going to be saved as Absolute price.': 'Diese Gruppe (oder eine der verschachtelten Gruppen) verfügt über einen prozentualen manuellen Preis. Dieser Preis wird als Absoluter Preis gespeichert.',
    'Looking for Group Template Suggestions': 'Suche nach Gruppenvorlagen-Vorschlägen',
    'No Group Template suggestions found': 'Keine Gruppenvorlagen-Vorschläge gefunden',
    'Group Template suggestions found': 'Gruppenvorlagen-Vorschläge gefunden',
    'Confirm': 'Bestätigen',
    'The following preset(s) can be used:': 'Folgende Voreinstellungen können verwendet werden:',
    'Add all preset features to the selected group': 'Fügen Sie alle voreingestellten Funktionen zur ausgewählten Gruppe hinzu',
    'Admin Panel': 'Admin-Panel',
    'Feature': 'Besonderheit ',
    'Preset': 'Voreingestellt',
    'Item will be pasted inside selected group': 'Das Element wird in die ausgewählte Gruppe eingefügt',
    'Item will be pasted below selected item': 'Das Element wird unter dem ausgewählten Element eingefügt',
    'Search for Products': 'Suchen nach Produkten',
    'Clear Search Input': 'Such-Eingabe löschen',
    'Search for Groups': 'Suchen nach Gruppen',
    'Destination quotation is not selected': 'Zielzitat ist nicht ausgewählt',
    'Default': 'Default',
    'Search type': 'Suchtyp',
    'Favorite Groups': 'Lieblingsgruppen',
    'Search Favourite Groups': 'Suche nach bevorzugten Gruppen',
    'Mark Group as Favourite': 'Gruppe als Favorit markieren',
    'Prices are only displayed and calculated in higher-level hierarchies for "Normal positions" (NO) and "Addendum positions" (AD).  "Optional positions" (OP) and "Alternative positions" (AL) display prices but are not included in the project calculation.': 'Die Preisanzeige und Verrechnung in übergeordneten Hierarchien erfolgt nur bei "Normalpositionen" (NO) und "Nachtragspositionen" (NA). Bei "Bedarfspositionen" (BE) und "Alternativpositionen" (AL) werden Preise zwar ausgewiesen, aber nicht verrechnet.',
    'Price is not included in the project calculation': 'Preis ist nicht im Projektverrechnung enthalten',
    'Number must not be less than 0': 'Die Zahl darf nicht kleiner als 0 sein',
    'Search by Project': 'Suche nach Projekt',
    'Search by Description': 'Suche nach Beschreibung',
  },
};

export default de;