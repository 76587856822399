import { useMutation } from '@apollo/client';
import { IconArrowRight } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ItemTypeChoices, MultipleItemType } from '../../../../__generated__/graphql';
import { ADD_QUOTATION_ITEMS_MUTATION } from '../../../../api/mutations/quotations/item';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import { classNames, prepareItemForMutation } from '../../../../helpers/utils';
import { LoadingIndicator } from '../../../../layout';
import { setCreateNewItemInside, unsetItemToSinglePasteBelow } from '../../../../redux/quotationSlice';
import { FlattenedItem } from '../../../shared/dnd/types';

interface Props {
  item: FlattenedItem,
}

export default function PasteArrow(props: Props) {
  const { item } = props;

  const destinationQuotationId = useAppSelector(state => state.search.QuotationContext.destinationQuotationId);
  const itemToSinglePasteBelow = useAppSelector(state => state.quotation.itemToSinglePasteBelow);
  const createNewItemInside = useAppSelector(state => state.quotation.createNewItemInside);
  const destinationProjectId = useAppSelector(state => state.search.QuotationContext.destinationProjectId);
  const searchQuotationId = useAppSelector(state => state.search.QuotationContext.searchQuotationId);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [
    addItemMutation,
    {
      data: addItemMutationData,
      loading: addItemMutationLoading,
    }] = useMutation(ADD_QUOTATION_ITEMS_MUTATION);

  const handlePasteTreeItem = () => {
    const items: { catalog?: string; externalId?: string; itemType: MultipleItemType; item?: string; }[] = [];
    items.push(prepareItemForMutation(item, !!searchQuotationId));      
    addItemMutation({
      variables: {
        items,
        order: itemToSinglePasteBelow.order as number,
        parent: (createNewItemInside ? itemToSinglePasteBelow.id : itemToSinglePasteBelow.parentId) as string,
        quotation: destinationQuotationId as string,
      },
    });
    dispatch(setCreateNewItemInside(false));
  };

  useEffect(() => {
    if (addItemMutationData) {
      navigate(`/quotation/${destinationProjectId}/${destinationQuotationId}`);
      dispatch(unsetItemToSinglePasteBelow());
    }
  }, [addItemMutationData, destinationProjectId, destinationQuotationId, dispatch, navigate]);

  // if item is Group then it can be pasted anywhere
  const isGroup = item?.itemType === ItemTypeChoices.Group;
  
  const canBePasted = destinationQuotationId && (createNewItemInside || itemToSinglePasteBelow.parentId || isGroup);

  const getTippyContent = () => {
    if (!destinationQuotationId) {
      return t('Destination quotation is not selected');
    }
    return canBePasted 
      ? t('Add to selected Quotation') 
      : t('Only Groups can be added to the root level');
  };

  return (
    <div className="w-6 h-6 flex justify-end items-center absolute top-1/4 right-1">
      { !addItemMutationLoading && !addItemMutationData &&
        <Tippy content={getTippyContent()} placement='left'>
          <IconArrowRight 
            className={classNames(canBePasted ? 'text-cblue-500 hover:text-cblue-700 cursor-pointer' : 'text-cgray-300')}
            onClick={() => canBePasted && handlePasteTreeItem()}
          />
        </Tippy>
      }
      {addItemMutationLoading && (
        <LoadingIndicator className="w-6 h-6 flex items-center justify-center" />
      )}
    </div>
  );
}