import { IconChevronDown } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import React, { CSSProperties, Dispatch, SetStateAction, forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getItemWidth } from '../../../../../helpers/dnd/utilities';
import { useAppDispatch, useAppSelector } from '../../../../../helpers/reduxHooks';
import { classNames, convertMinutesToTimeLabel, getListRowStyles } from '../../../../../helpers/utils';
import { setSelectedItem } from '../../../../../redux/quotationSlice';
import styles from '../../../../shared/dnd/TreeItem.module.css';
import { TreeItemIcon } from '../../../../shared/dnd/TreeItemIcon';
import { FlattenedItem } from '../../../../shared/dnd/types';
import ROQuotationGroupDetails from '../details/groupDetails/ROQuotationGroupDetails';
import HoveredGroupContextMenu from './HoveredGroupContextMenu';
import NetSalesPriceCell from './NetSalesPriceCell';
import WrapperRowGroup from './WrapperRowGroup';

interface Props {
  collapsed?: boolean;
  item: FlattenedItem;
  handleProps?: object;
  onCollapse?(): void;
  clone?: boolean;
  depth: number;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  indicator?: boolean;
  indentationWidth: number;
  style: CSSProperties,
  wrapperRef?(node: HTMLLIElement): void;
  showDetails: boolean,
  setShowDetails: Dispatch<SetStateAction<boolean>>,
}

const RODefaultRowGroup = forwardRef<HTMLDivElement, Props>(
  (
    {
      depth, indentationWidth, onCollapse, style, item, showDetails, setShowDetails,
      collapsed, clone, disableInteraction, disableSelection, ghost, indicator, wrapperRef,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const copiedItem = useAppSelector(state => state.quotation.copiedItem);
    const maximumQuotationUncollapsedDepth = useAppSelector(state => state.quotation.maximumQuotationUncollapsedDepth);
    const selectedItem = useAppSelector(state => state.quotation.selectedItem);
    const itemToCut = useAppSelector(state => state.quotation.itemToCut);
    const itemToUncollapse = useAppSelector(state => state.quotation.itemToUncollapse);
    const itemToCollapse = useAppSelector(state => state.quotation.itemToCollapse);
    const dispatch = useAppDispatch();

    const [isItemHoveredId, setIsItemHovered] = useState<string | null>(null);

    const selectTreeItem = () => {
      dispatch(setSelectedItem(item));
    };

    const groupPropsWrapper = {
      collapsed, indentationWidth, depth, style, clone, indicator,
      ref, wrapperRef, ghost, disableSelection, disableInteraction,
    };

    useEffect(() => {
      if (collapsed && itemToUncollapse === item.id && onCollapse) {
        onCollapse();
      }
      if (!collapsed && itemToCollapse === item.id && onCollapse) {
        onCollapse();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemToUncollapse, itemToCollapse]);

    return (
      <WrapperRowGroup {...groupPropsWrapper}>
        <>
          <div
            onClick={selectTreeItem}
            className={classNames(styles.TreeItem)}
            style={getListRowStyles(style, item, copiedItem, selectedItem, itemToCut, showDetails) as React.CSSProperties}

            onMouseEnter={() => setIsItemHovered(item.id.toString())}
            onMouseLeave={() => setIsItemHovered(null)}

          >
            <div
              className={classNames('flex', onCollapse ? 'justify-between' : 'justify-end')}
              style={{ width: `${getItemWidth(maximumQuotationUncollapsedDepth, indentationWidth, depth)}px` }}
            >
              {onCollapse && (
                <div onClick={onCollapse}>
                  <IconChevronDown
                    className={classNames(
                      styles.Collapse,
                      collapsed && styles.collapsed,
                      collapsed ? '-rotate-90' : 'rotate-0',
                      'transition-all duration-200 ease-in-out cursor-pointer ml-0 text-cblue-500',
                    )}
                  />
                </div>
              )}
            </div>
            {item.group?.isProductionUnit && item.group?.productionStatus && (
              <div
                className="absolute z-10 top-0 left-10 w-6 rotate-180 font-medium text-white overflow-hidden whitespace-nowrap h-14 shadow-lg rounded-tr rounded-tl"
                style={{
                  writingMode: 'vertical-rl',
                  backgroundColor: item.group.productionStatus.color,
                }}
              >
                <Tippy
                  content={item.group.productionStatus.title}
                  placement='bottom'
                  disabled={item.group.productionStatus.title.length < 7}
                >
                  <span className='truncate cursor-default' style={{ marginTop: '0.5rem' }}>
                    {item.group?.productionStatus.title}
                  </span>
                </Tippy>
              </div>
            )}
            <div className='w-14 py-3.5 flex justify-between'>
              <TreeItemIcon
                item={item}
                setShowDetails={setShowDetails}
                showDetails={showDetails}
                groupIconModifiers={{
                  representsTemplate: !!item.group?.representsTemplate,
                }}
              />
            </div>
            <div className='flex items-center gap-2 2xl:gap-5 3xl:gap-8'>
              <Tippy content={item.position} placement="right" disabled={item.position !== undefined && item.position.length < 10}>
                <div className='w-20 overflow-clip text-ellipsis text-right' dir="rtl">{item.position}</div>
              </Tippy>
              <Tippy 
                content={['AL', 'OP'].includes(item.group?.positionType?.slice(0, 2) || '') ? t('Price is not included in the project calculation') : ''} 
                placement="top" 
                disabled={!['AL', 'OP'].includes(item.group?.positionType?.slice(0, 2) || '')}
              >
                <div className="w-16 pl-1">{item.group?.positionType?.slice(0, 2)}</div>
              </Tippy>
              <div className='w-11 text-right'>{item.quantity}</div>
              <div className="w-32"></div>
              <Tippy content={item.group?.description} disabled={!!item.group?.description && item.group?.description.length < 60} placement="top">
                <div className="w-96 3xl:w-[450px] 4xl:w-[800px] truncate pl-2">{item.group?.description}</div>
              </Tippy>
              <div className="w-20"></div>
              <Tippy content={t('Sum of nested time values, excluding Sales time')} placement="top" >
                <div className="w-12 text-center">{convertMinutesToTimeLabel(item.group?.groupComputation?.time as number)}</div>
              </Tippy>
            </div>
            <div className='flex items-center justify-between 3xl:grow'>
              <div className="w-16"></div>
              <div className="w-16"></div>
              <div className="w-20 pl-1">{item.group?.groupComputation?.netPricePieceLabel}</div>
              <div className="pl-1 w-28 flex flex-col relative">
                <NetSalesPriceCell
                  hasManualSalesPricePiece={item.group?.hasManualSalesPricePiece || false}
                  calculatedNetSalesPricePieceIncludingDiscountLabel={item.group?.groupComputation?.calculatedNetSalesPricePieceIncludingDiscountLabel as string}
                  netSalesPricePieceIncludingDiscountLabel={item.group?.groupComputation?.netSalesPricePieceIncludingDiscountLabel as string}
                />
              </div>
              <div className="w-28 pl-1">{item.group?.groupComputation?.totalSalesPriceLabel}</div>
            </div>
            {isItemHoveredId === item.id && (
              <HoveredGroupContextMenu
                item={item}
                isItemHoveredId={isItemHoveredId}
                setIsItemHoveredId={setIsItemHovered}
                showDetails={showDetails}
                setShowDetails={setShowDetails}
              />
            )}
          </div>
          {showDetails && item.group && (
            <ROQuotationGroupDetails
              groupId={item.group.id}
              indentationWidth={indentationWidth}
              depth={depth}
              maximumUncollapsedDepth={maximumQuotationUncollapsedDepth}
            />
          )}
        </>
      </WrapperRowGroup>
    );
  },
);

export default RODefaultRowGroup;