import { IconChevronDown, IconX } from '@tabler/icons-react';
import { useEffect, useRef, useState, forwardRef } from 'react';
// eslint-disable-next-line import/named
import Select, { components } from 'react-select';
import type { SelectInstance, GroupBase, InputProps } from 'react-select';
import { classNames } from '../../helpers/utils';


interface Props {
  options?: SelectOption[],
  placeholder?: string,
  isMulti?: boolean,
  isLoading?: boolean,
  isClearable?: boolean,
  isSearchable?: boolean,
  onChange?: (value: SelectOption) => void,
  onMultiChange?: (value: SelectOption[]) => void,
  onInputChange?: (value: string) => void,
  value?: SelectOption,
  multiValue?: SelectOption[],
  showLabel?: boolean,
  defaultValue?: SelectOption | SelectOption[],
  isDisabled?: boolean,
  openMenuOnFocus?: boolean,
  onMenuClose?:(() => void), 
  showColorDot?: boolean
}

function DropdownIndicator(props: any) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <IconChevronDown 
        className={classNames(props.isDisabled ? 'text-cgray-400 hover:text-cgray-400 focus:text-cgray-400' : 'text-cblue-500 hover:text-cblue-500 focus:text-cblue-500', 'h-6 w-6')}
      />
      </components.DropdownIndicator>
  );
}

function ClearIndicator(props: any) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.ClearIndicator {...props}>
      <IconX className="-mr-2 h-5 w-5 text-cgray-400 hover:text-cblue-500 cursor-pointer" />
    </components.ClearIndicator>
  );
}

function Input(props: JSX.IntrinsicAttributes & InputProps<unknown, boolean, GroupBase<unknown>>) {
  return (
    <components.Input {...props} 
      style={{
        color: '#282828',
        caretColor: '#005DA3',
        background: '0px center',
        opacity: 1,
        width: '100%',
        gridArea: '1 / 2 / auto / auto',
        minWidth: '2px',
        border: '0px',
        margin: '0px',
        outline: '0px',
        padding: '0px',
        boxShadow: 'none',
      }} 
    />
  );
}

const SelectInput = forwardRef((props: Props, ref: React.Ref<SelectInstance> ) => {
  const {
    options, placeholder, isMulti, onChange, onInputChange, isLoading, isDisabled,
    showLabel: showLabelInitially, value, multiValue, defaultValue, onMultiChange,
    isClearable, isSearchable, openMenuOnFocus, onMenuClose, showColorDot,
  } = props;
  const innerReference = useRef<SelectInstance>(null);
  const resolvedRef = ref || innerReference;

  const [showLabel, setShowLabel] = useState(!!showLabelInitially);
  const [selectFocused, setSelectFocused] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  
  const handleChange = (selectedOption: SelectOption) => {
    const isCurrentSelected = !!selectedOption;
    setShowLabel(isCurrentSelected);
    if (onChange) {
      onChange(selectedOption);
    }
  };

  const handleMuliSelectChange = (selectedOption: SelectOption[]) => {
    const isCurrentSelected = !!selectedOption?.length;
    setShowLabel(isCurrentSelected);
    if (onMultiChange) {
      onMultiChange(selectedOption);
    }
  };

  useEffect(() => {
    if (menuIsOpen) {
      //@ts-ignore
      resolvedRef.current?.onMenuOpen();
      //@ts-ignore
    } else resolvedRef.current?.onMenuClose();
  }, [menuIsOpen, resolvedRef]);


  const CustomOption = (optionProps: any) => {
    return (
      <components.Option {...optionProps}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              backgroundColor: optionProps.data.dotColor || 'transparent',
              borderRadius: '50%',
              display: 'inline-block',
              height: 10,
              width: 10,
              marginRight: 8,
            }}
          ></span>
          {optionProps.data.label}
        </div>
      </components.Option>
    );
  };


  const CustomSingleValue = (singleProps: any) => {
    return (
      <components.SingleValue {...singleProps}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              backgroundColor: singleProps.data.dotColor || 'transparent',
              borderRadius: '50%',
              display: 'inline-block',
              height: 10,
              width: 10,
              marginRight: 8,
            }}
          ></span>
          {singleProps.data.label}
        </div>
      </components.SingleValue>
    );
  };

  return (
    <div className="relative h-full">
      <Select
        ref={resolvedRef}
        value={multiValue || value || undefined}
        defaultValue={defaultValue}
        components={
          { 
            DropdownIndicator,
            ClearIndicator,
            Input,
            Option: showColorDot ? CustomOption : components.Option,
            SingleValue: showColorDot ? CustomSingleValue : components.SingleValue, 
          }
        }
        placeholder={placeholder}
        options={options}
        isMulti={!!isMulti}
        isLoading={isLoading}
        isClearable={!!isClearable}
        isSearchable={!!isSearchable}
        onInputChange={onInputChange}
        // @ts-ignore
        onChange={isMulti ? handleMuliSelectChange : handleChange}
        onFocus={() => setSelectFocused(true)}
        onBlur={() => setSelectFocused(false)}
        isDisabled={isDisabled}
        openMenuOnFocus={openMenuOnFocus}
        onMenuClose={() => {
          if (onMenuClose) {onMenuClose();}
        }}
        styles={{
          container: (styles) => ({
            ...styles,
            height: '100%',
          }),
          control: (styles, { isFocused }) => ({
            ...styles,
            fontSize: '14px',
            backgroundColor: isFocused ? '#e9e9e9' : '#f4f4f4',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            height: '100%',
            border: 0,
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#e9e9e9',
            },
          }),
          placeholder: (styles) => ({
            ...styles,
            color: '#b2b2b2',
            fontWeight: 500,
            fontSize: 14,
          }),
          input: (styles) => ({
            ...styles,
            color: '#282828',
          }),
          indicatorsContainer: (styles) => ({
            ...styles,
            padding: 0,
          }),
          dropdownIndicator: (styles, state) => ({
            ...styles,
            padding: 0,
            margin: '8px 8px 8px 0px',
            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
            transition: 'all .15s ease',
          }),
          clearIndicator: (styles) => ({
            ...styles,
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          menu: (styles) => ({
            ...styles,
            backgroundColor: '#ffffff',
            boxShadow: '0px 6px 6px #d3d3d3',
            paddingTop: 0,
            marginTop: 0,
            border: 'none',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            zIndex: 30,
          }),
          menuList: (styles) => ({
            ...styles,
            padding: 0,
            margin: 0,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
          }),
          option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            color: '#282828',
            backgroundColor: isSelected
              ? '#caddeb'
              : isFocused
                ? '#e4edf5'
                : '#ffffff',
          }),
          valueContainer: (styles) => ({
            ...styles,
            paddingBottom: 0,
            boxSizing: 'border-box',
          }),
          singleValue: (styles) => ({
            ...styles,
            color: isDisabled ? '#b2b2b2' : '#282828',
          }),
          multiValue: (styles) => ({
            ...styles,
            margin: '10px 1px 0 0',
            borderRadius: '4px',
            border: 'solid 1px',
            borderColor: '#e4e4e7',
            backgroundColor: 'white',
          }),
          multiValueLabel: (styles) => ({
            ...styles,
            ...(isDisabled ? { color: '#b2b2b2' } : { color: '#005DA3' }),
            padding: '1px 3px 1px',
          }),
          multiValueRemove: (styles) => ({
            ...styles,
            padding: 0,
            color: '#b2b2b2',
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#caddeb',
            primary50: '#caddeb',
          },
        })}
      />
      <div className={classNames(
        selectFocused ? 'bg-cblue-500' : 'bg-transparent',
        'absolute bottom-0 left-0 h-px w-full',
      )}
      />
      {(value || multiValue?.length || showLabel) && (
        <div
          onClick={()=>!isDisabled && setMenuIsOpen(!menuIsOpen)}
          className={classNames(selectFocused ? 'text-cblue-500' : 'text-cgray-400', 'absolute top-0.5 left-2.5 text-2xs cursor-default')}>
          <div className='truncate w-full'>
            {placeholder}
          </div>
        </div>
      )}
    </div>
  );
});

export default SelectInput;
