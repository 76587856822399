import { IconChevronDown, IconX } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { SingleValue } from 'react-select';
import Select from 'react-select';
import { classNames } from '../../helpers/utils';

const Menu = (props: JSX.IntrinsicElements['div']) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 48,
        position: 'absolute',
        zIndex: 30,
      }}
      {...props}
    />
  );
};
const Blanket = (props: JSX.IntrinsicElements['div']) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);

const Dropdown = ({ 
  children, 
  isOpen, 
  target, 
  onClose,
}: {
  children?: ReactNode; 
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
}) => (
  <div className=" relative pl-1 w-full h-full min-h-[40px] flex items-center rounded-t bg-cgray-100 hover:bg-cgray-200"
  >
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

interface Props {
  value?: SelectOption,
  options?: SelectOption[],
  onChange: (value?: SingleValue<SelectOption>) => void,
  label?: string,
  buttonLabel: string,
  isClearable?: boolean,
  className?: string,
  positionType?: string,
  onFocus?: () => void,
  onBlur?: () => void,
  menuIsOpen?: boolean,
}

function PopoutInput(props: Props) {
  const { 
    value, 
    options, 
    onChange, 
    label, 
    buttonLabel, 
    isClearable, 
    className, 
    positionType,
    onFocus,
    onBlur,
    menuIsOpen,
  } = props;
  const [isOpen, setIsOpen] = useState(!!menuIsOpen);
  const [selectedValue, setSelectedValue] = useState(value);
  const { t } = useTranslation();

  const handleFocus = () => {
    setIsOpen(true);
    onFocus?.();
  };

  const handleBlur = () => {
    setIsOpen(false);
    onBlur?.();
  };
  
  return (
    <Dropdown
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        onBlur?.();
      }}
      target={
        <>
          {label && (
            <label
              className="absolute top-0 left-2 text-2xs text-cgray-400 cursor-pointer"
              onClick={() => setIsOpen((prev) => !prev)}
            >
              {label}
            </label>
          )}
          <Tippy 
            content={['AL', 'OP'].includes(positionType?.slice(0, 2) || '') ? t('Price is not included in the project calculation') : ''} 
            placement="top" 
            disabled={!['AL', 'OP'].includes(positionType?.slice(0, 2) || '')}
          >
            <button
              onClick={() => setIsOpen((prev) => !prev)}
              className={classNames(`${className} h-full w-full flex items-center`)}
              tabIndex={0}
              onFocus={handleFocus}
              onBlur={handleBlur}
            >
              <div className='w-full flex grow'>{ buttonLabel }</div>
              <div className='flex justify-end grow-0'>
                {value && isClearable && 
                <IconX 
                  onClick={(e) => {
                    onChange(); 
                    e.stopPropagation();
                  }}
                  className='text-cgray-500 w-4'
                />}
                <IconChevronDown className={classNames(isOpen ? 'rotate-180 duration-150' : 'duration-500', 'text-cblue-500 transform transition-all ease-out')} />
              </div>
            </button>
          </Tippy>
        </>
      }
    >
      <Select
        autoFocus
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={isClearable}
        menuIsOpen
        onChange={(newValue) => {
          onChange(newValue);
          setSelectedValue(newValue as SelectOption);
          setIsOpen(false);
        }}
        options={options}
        value={selectedValue}
        tabSelectsValue={false}
        styles={{
          container: (styles) => ({
            ...styles,
            height: 0,
          }),
          control: (styles) => ({
            ...styles,
            minWidth: '220px',
            height: 0,
          }),
          placeholder: () => ({
            display: 'none',
          }),
          input: () => ({
            display: 'none',
          }),
          indicatorsContainer: () => ({
            display: 'none',
          }),
          dropdownIndicator: () => ({
            display: 'none',
          }),
          clearIndicator: () => ({
            display: 'none',
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          menu: (styles) => ({
            ...styles,
            backgroundColor: '#ffffff',
            boxShadow: '0px 6px 6px #d3d3d3',
            paddingTop: 0,
            marginTop: 0,
            border: 'none',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            minWidth: 'fit-content',
          }),
          menuList: (styles) => ({
            ...styles,
            padding: 0,
            margin: 0,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
          }),
          option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            color: '#282828',
            backgroundColor: isSelected
              ? '#caddeb'
              : isFocused
                ? '#e4edf5'
                : '#ffffff',
            textAlign: 'left',
          }),
          valueContainer: (styles) => ({
            ...styles,
            paddingBottom: 0,
            boxSizing: 'border-box',
          }),
          singleValue: (styles) => ({
            ...styles,
            color: '#282828',
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#caddeb',
            primary50: '#caddeb',
          },
        })}
      />
    </Dropdown>
  );
}

export default PopoutInput;
