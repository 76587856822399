import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { UPDATE_SERVICE_TIME } from '../../../../../api/mutations/quotations/service';
import { SelectedColumnName } from '../../../../../constants';
import { useQuotationItemInputHandler } from '../../../../../helpers/customHooks';
import { useAppDispatch, useAppSelector } from '../../../../../helpers/reduxHooks';
import { classNames, convertMinutesToTimeLabel, handleInputKeyDown } from '../../../../../helpers/utils';
import { setSelectedColumn } from '../../../../../redux/quotationSlice';

export interface Props {
  refetchQueries: InternalRefetchQueriesInclude,
  serviceTime: number,
  id: ID,
  label?: string,
}

interface FormValues {
  serviceTime: number | undefined,
  service: ID,
  quotation: ID,
}

export default function ServiceTimeInput(props: Props) {
  const { serviceTime, id, refetchQueries, label } = props;
  const [inputFocused, setInputFocused] = useState(false);
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [isClickedTwice, setIsClickedTwice] = useState(false);
  const { quotationId } = useParams();
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const fieldName = 'serviceTime';
  const selectedColumn = useAppSelector(state => state.quotation.selectedColumn);
  const selectedItem = useAppSelector(state => state.quotation.selectedItem);
  const dispatch = useAppDispatch();

  const validationSchema = yup.object({
    [fieldName]: yup.number()
      .integer()
      .min(0, t('Number must not be less than 0'))
      .required(t('Required')),
  });

  const initialValues: FormValues = {
    serviceTime: serviceTime as number,
    service: id,
    quotation: quotationId as ID,
  };

  const { handleChange, handleSubmit, values, handleBlur, isError, setFieldValue } = useQuotationItemInputHandler({
    mutation: UPDATE_SERVICE_TIME,
    fieldName,
    validationSchema,
    initialValues,
    refetchQueries,
    setInputFocused,
    inputFocused,
  });

  // For vertical moving
  useEffect(() => {
    if (selectedColumn === SelectedColumnName.TIME && selectedItem?.service?.id === id) {
      inputRef.current?.focus();
    }
  }, [selectedItem, selectedColumn, id]);

  const hours = Math.floor(values.manufacturingTime as number / 60);

  const inputClass = classNames(
    !inputFocused && hours > 99 ? 'text-xs' : 'text-sm',
    inputFocused && hours >= 16 ? 'text-xs' : 'text-sm',
    (inputFocused || isError) ? 'text-left pl-1' : 'text-center pl-0',
    (inputFocused && !isError) && 'border-cblue-500',
    isError && 'border-cred-500',
    'border-b w-full h-full flex items-center justify-end rounded-t bg-cgray-100 hover:bg-cgray-200 focus:outline-none focus:bg-cgray-200 group-hover:bg-cgray-200',
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    handleInputKeyDown({
      e,
      isClickedTwice,
      isClickedOnce,
      inputFocused,
      inputRef,
      handleSubmit,
      handleInputBlur,
      setIsClickedTwice,
      setFieldValue,
      fieldName,
      originalValue: serviceTime,
    });
  };

  const handleClick = () => {
    if (inputRef.current) {
      if (isClickedOnce && !isClickedTwice) {
        setIsClickedTwice(true);
      } else if (!isClickedOnce) {
        inputRef.current.focus();
        setInputFocused(true);
        setIsClickedOnce(true);
        dispatch(setSelectedColumn(SelectedColumnName.TIME));
      }
    }
  };

  // Select the input value when it's transformed to minutes
  useEffect(() => {
    if (selectedColumn === SelectedColumnName.TIME && selectedItem?.service?.id === id) {
      inputRef.current?.select();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClickedTwice]);

  const handleInputBlur = () => {
    handleBlur();
    setIsClickedTwice(false);
    setIsClickedOnce(false);
  };

  return (
    <div className="relative h-full flex items-center group" onClick={handleClick}>
      {label && (
        <label 
          htmlFor={fieldName} 
          className={classNames(isError ? 'text-cred-500' : 'text-cgray-400', 'absolute top-0 left-2 text-2xs cursor-text')}
          onClick={() => inputRef.current?.focus()}
        >
          {label}
        </label>
      )}
      <input
        name={fieldName}
        ref={inputRef}
        value={isClickedTwice || isError
          ? values[fieldName]
          : convertMinutesToTimeLabel(values[fieldName])
        }
        onChange={handleChange}
        className={inputClass}
        readOnly={!isClickedTwice}
        onFocus={() => {
          setInputFocused(true);
          dispatch(setSelectedColumn(SelectedColumnName.TIME));
        }}
        onBlur={handleInputBlur}
        onKeyDown={handleKeyDown}
      />
      {(isClickedTwice || isError) && <div className={classNames(hours >= 16 ? 'text-xs' : 'text-sm', 'absolute h-full right-1 top-0 flex items-center')}>m</div>}
    </div>
  );
}
