import { useQuery } from '@apollo/client';
import { IconPlus } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  GroupManualSalesPricePieceType,
  ProjectsProjectProjectStatusChoices,
  QuotationGroupDetailsType,
  QuotationGroupFeatureType,
  QuotationVersionTypeChoices,
  QuotationsGroupManualSalesPricePiecePriceTypeChoices,
} from '../../../../../../__generated__/graphql';
import { PROJECT_QUERY } from '../../../../../../api/queries/projects';
import { QUOTATION_GROUP_DETAILS_QUERY } from '../../../../../../api/queries/quotations/details';
import { PRODUCTION_UNIT_STATUSES_QUERY } from '../../../../../../api/queries/quotations/group';
import { QUOTATION_QUERY } from '../../../../../../api/queries/quotations/quotation';
import { CURRENT_USER_QUERY } from '../../../../../../api/queries/users';
import { getItemWidth } from '../../../../../../helpers/dnd/utilities';
import { useAppDispatch } from '../../../../../../helpers/reduxHooks';
import { LoadingIndicator } from '../../../../../../layout';
import {
  setActiveQuotationGroupId,
  setIsPresetSuggestionOpened,
  setSelectedGroupFeature,
  setShowSelectFeatureModal,
} from '../../../../../../redux/quotationSlice';
import { DescriptionInput, DiscountInput, NotesInput } from '../../../fields/group';
import FavouriteButton from './FavouriteButton';
import EtimGroupFeature from './groupFeature/EtimGroupFeature';
import SelectClassForm from './groupFeature/SelectClassForm';
import GroupTemplatesLookup from './GroupTemplatesLookup';
import GroupManualPriceTypeSelect from './manualPrice/GroupManualPriceTypeSelect';
import GroupManualPriceValueInput from './manualPrice/GroupManualPriceValueInput';
import ToggleManualPriceForm from './manualPrice/ToggleManualPriceForm';
import GroupProductionUnitStatusSelect from './productionUnit/GroupProductionUnitStatusSelect';
import UpdateProductionUnitForm from './productionUnit/UpdateProductionUnitForm';

interface Props {
  groupId: ID,
  itemId: ID,
  indentationWidth: number;
  depth: number;
  maximumUncollapsedDepth: number;
}

export default function GroupDetailsEditable(props: Props) {
  const { groupId, itemId, indentationWidth, depth, maximumUncollapsedDepth } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { projectId, quotationId } = useParams();


  const { data: statusesData } = useQuery(
    PRODUCTION_UNIT_STATUSES_QUERY,
  );

  const { data: userData } = useQuery(CURRENT_USER_QUERY);

  const {
    data: groupData,
    loading: groupLoading,
  } = useQuery(QUOTATION_GROUP_DETAILS_QUERY, {
    variables: {
      group: groupId as string,
    },
  });

  const {
    data: projectData,
    loading: projectLoading,
  } = useQuery(PROJECT_QUERY, {
    variables: {
      project: projectId as string,
    },
  });

  const {
    data: quotationData,
    loading: quotationLoading,
  } = useQuery(QUOTATION_QUERY, {
    variables: {
      quotation: quotationId as string,
    },
  });

  const refetchQueries = [{
    query: QUOTATION_QUERY,
    variables: {
      quotation: quotationId as string,
    },
  },
  {
    query: QUOTATION_GROUP_DETAILS_QUERY,
    variables: {
      group: groupId as string,
    },
  },
  ];

  const refetchQueriesOnPUStatusChange = [
    ...refetchQueries,
    {
      query: PROJECT_QUERY,
      variables: { project: projectId as string },
    },
  ];

  const PUStatusOptions = statusesData && statusesData.productionUnitStatuses && statusesData.productionUnitStatuses.response
    ? statusesData.productionUnitStatuses.response.map((status) => (
      { value: status?.id, label: status?.title }) as SelectOption)
    : [];

  const manualPriceTypeOptions: SelectOption[] = Object.values(QuotationsGroupManualSalesPricePiecePriceTypeChoices).map(
    (priceType) => ({
      label: t(priceType.charAt(0).toUpperCase() + priceType.slice(1).toLowerCase()),
      value: priceType,
    }),
  );

  const group = groupData && groupData.quotationGroupDetails && groupData.quotationGroupDetails.response;
  const project = projectData && projectData.project && projectData.project.response;
  const quotation = quotationData?.quotation?.response;
  
  const user = userData && userData.user;
  if (groupLoading) {
    return (
      <div className='p-2.5 pr-5 border-[1px] border-t-0 rounded-bl rounded-br border-cgray-300 flex text-xs 2xl:text-sm text-cgray-400'>
        <LoadingIndicator className="w-full flex h-56 justify-center items-center" />
      </div>
    );
  } else if (!quotationLoading && !groupLoading && !projectLoading && group) {
    return (
      <div className='p-2.5 pr-5 flex border border-t-0 rounded-bl rounded-br border-cgray-300 text-xs 2xl:text-sm text-cgray-400 z-20 bg-white'>
        <div
          className="flex justify-end"
          style={{ width: `${getItemWidth(maximumUncollapsedDepth, indentationWidth, depth)}px` }}
        ></div>
        <div className="flex flex-col w-full text-sm text-cgray-600 whitespace-normal">
          <div className="grid grid-cols-12 w-full gap-4 text-sm text-cgray-600">
            <div className='col-span-4 flex flex-col gap-2'>
              <div className="mb-2 font-bold text-cgray-600 flex">
                <span className='mr-1'>{t('General')}</span>
                {user?.isSuperuser && (
                  <FavouriteButton
                    groupId={group.id}
                    isFavourite={!!group?.isFavourite}
                  />
                )}
                </div>
              <div className='grid grid-cols-4 gap-2 h-10'>
                <div className="col-span-3 h-10">
                  <DescriptionInput
                    refetchQueries={refetchQueries}
                    description={group?.description as string}
                    id={group?.id}
                    label
                  />
                </div>
                <div className="col-span-1 h-10">
                  <DiscountInput
                    refetchQueries={refetchQueries}
                    discount={group?.discount}
                    id={group?.id}
                    label
                  />
                </div>
              </div>
              <div className='h-36 bg-cgray-100 cursor-text col-span-4 flex flex-col py-px rounded-t grow'>
                <p className=" text-2xs text-cgray-400">{t('Notes')}</p>
                <NotesInput
                  notes={group?.notes}
                  id={group?.id}
                  refetchQueries={refetchQueries}
                />
              </div>
            </div>
            <div className='col-span-4 flex flex-col gap-2'>
              <div className="mb-2 font-bold text-cgray-600">{t('ETIM')}</div>
              <div className='grid grid-cols-4 gap-2'>
                <div className='col-span-2 h-10'>
                  <SelectClassForm group={group as QuotationGroupDetailsType} />
                </div>
                <div
                  className='col-span-1 flex justify-center items-center bg-cgray-100 hover:bg-cgray-200 rounded-t relative group cursor-pointer text-2xl h-10'
                  onClick={() => {
                    dispatch(setSelectedGroupFeature());
                    dispatch(setShowSelectFeatureModal(true));
                    dispatch(setActiveQuotationGroupId(group?.id as string));
                  }}
                >
                  <IconPlus className='h-5 w-5' />
                  <span className='text-sm'>{t('Feature')}</span>
                </div>
                <div
                  className='col-span-1 flex justify-center items-center bg-cgray-100 hover:bg-cgray-200 rounded-t relative group cursor-pointer text-2xl h-10'
                  onClick={() => {
                    dispatch(setIsPresetSuggestionOpened(true));
                    dispatch(setActiveQuotationGroupId(group?.id as string));
                  }}
                >
                  <IconPlus className='h-5 w-5' />
                  <span className='text-sm'>{t('Preset')}</span>
                </div>
              </div>
              <div className='flex gap-4 items-center justify-start flex-wrap'>
                {group?.groupFeatures && group.groupFeatures.map((feature) => (
                  <Fragment key={feature?.id}>
                    {feature
                      ?
                      <EtimGroupFeature
                        item={feature as QuotationGroupFeatureType}
                        groupId={group?.id}
                      />
                      : <></>
                    }
                  </Fragment>
                ))
                }
              </div>
            </div>
            <div className='grid col-grid-2 col-span-4 gap-y-4'>
              <div>
                <div className="font-bold text-cgray-600">{t('Production')}</div>
                <div className='flex flex-col gap-4'>
                  <div className='pt-4'>
                    <UpdateProductionUnitForm
                      groupId={group.id}
                      isProductionUnit={group.isProductionUnit}
                      refetchQueries={refetchQueriesOnPUStatusChange}
                    />
                  </div>
                  <div className='w-2/4'>
                    {!PUStatusOptions.length
                      ? (
                        <div className='p-6 bg-cyellow-200 text-cred-700 rounded shadow'>
                          <div>{t('No Statuses for Production Units available.')}</div>
                          <div>{t('Please contact your administrator.')}</div>
                        </div>

                      )
                      : (
                        quotation?.versionType === QuotationVersionTypeChoices.Readonly || project?.projectStatus !== ProjectsProjectProjectStatusChoices.Production || !group.isProductionUnit
                          ? (
                            <Tippy content={t('Production Unit Status can be changed only if Project Status is "Production"')} placement="top" >
                              <div className='cursor-default col-span-4 flex flex-col pl-2 py-px rounded-t border border-cgray-200'>
                                <p className="text-2xs text-cgray-400">{t('Production Unit Status')}</p>
                                <p className='text-sm overflow-hidden grow h-5'>{group?.productionUnitStatus?.title}</p>
                              </div>
                            </Tippy>
                          )
                          : (
                            <GroupProductionUnitStatusSelect
                              group={group as QuotationGroupDetailsType}
                              quotationId={quotationId as string}
                              refetchQueries={refetchQueries}
                              options={PUStatusOptions}
                            />
                          )
                      )
                    }
                  </div>
                </div>
              </div>
              <div>
                <div className="font-bold text-cgray-600">{t('Manual Price')}</div>
                <div className='flex flex-col gap-4'>
                  <div className='pt-4'>
                    <ToggleManualPriceForm
                      groupId={group.id}
                      hasManualPrice={group.hasManualSalesPricePiece}
                      refetchQueries={refetchQueriesOnPUStatusChange}
                    />
                  </div>
                  <div className='grid grid-cols-4 gap-x-2'>
                    <div className='col-span-3'>
                      {quotation?.versionType === QuotationVersionTypeChoices.Readonly || !group.hasManualSalesPricePiece || !group.manualSalesPricePiece
                        ? (
                          <div className='cursor-default col-span-4 flex flex-col pl-2 py-px rounded-t border border-cgray-200'>
                            <p className="text-2xs text-cgray-400">{t('Price type')}</p>
                            <p className='text-sm overflow-hidden grow h-5'>
                              {manualPriceTypeOptions.find(item => item.value === group?.manualSalesPricePiece?.priceType)?.label || ''}
                            </p>

                          </div>
                        )
                        : (
                          <GroupManualPriceTypeSelect
                            groupManualSalesPricePiece={group.manualSalesPricePiece as GroupManualSalesPricePieceType}
                            refetchQueries={refetchQueries}
                            options={manualPriceTypeOptions}
                          />
                        )
                      }
                    </div>
                    <div>
                      {quotation?.versionType === QuotationVersionTypeChoices.Readonly || !group.hasManualSalesPricePiece || !group.manualSalesPricePiece
                        ? (
                          <div className='cursor-default col-span-4 flex flex-col pl-2 py-px rounded-t border border-cgray-200'>
                            <p className="text-2xs text-cgray-400">{t('Price Value')}</p>
                            <p className='text-sm overflow-hidden grow h-5'>
                              {group.manualSalesPricePiece?.valueLabel}
                            </p>

                          </div>
                        )
                        : (
                          <GroupManualPriceValueInput
                            groupManualSalesPricePiece={group.manualSalesPricePiece as GroupManualSalesPricePieceType}
                            refetchQueries={refetchQueries}
                            label
                          />
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div className="col-span-12 justify-end w-full flex">
                <div className="inline-flex">
                  <GroupTemplatesLookup itemId={itemId} />
                </div>
              </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (<></>);
  }
}
