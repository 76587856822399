import { useMutation } from '@apollo/client';
import { IconStarFilled, IconStar } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import { TOGGLE_QUOTATION_GROUP_FAVOURITE_STATUS } from '../../../../../../api/mutations/quotations/group';
import {
  QUOTATION_GROUP_DETAILS_QUERY,
} from '../../../../../../api/queries/quotations/details';
import { LoadingIndicator } from '../../../../../../layout';
// import { useState } from 'react';

interface Props {
  groupId: string,
  isFavourite: boolean
}

function FavouriteButton(props: Props) {
  const { groupId, isFavourite } = props;
  const { t } = useTranslation();

  const [
    toggleFavouriteStatus,
    {
      loading: toggleFavouriteStatusLoading,
    },
  ] = useMutation(TOGGLE_QUOTATION_GROUP_FAVOURITE_STATUS, {
    // Looks like adding this line syncs up mutation loading time
    // This way icon display works like tihs:
    // Initial state
    // button clicked -> loading is showing all the time mutation and following query is performed
    // Reversed state
    onQueryUpdated: () => { return true; },
    refetchQueries: [
      {
        query: QUOTATION_GROUP_DETAILS_QUERY,
        variables: {
          group: groupId,
        },
      },
    ],
  },
  );

  const handleFavoriteClick = () => {
    toggleFavouriteStatus(
      {
        variables: {
          group: groupId,
        },
      },
    );
  };

  return (
    <div onClick={handleFavoriteClick}>
      {toggleFavouriteStatusLoading ? (
        <div className='w-5 h-5'>
          <LoadingIndicator size="sm" />
        </div>
      ) : (
        <Tippy content={t('Mark Group as Favourite')} placement="right" >
          {isFavourite ? (
            <IconStarFilled className='w-5 h-5 cursor-pointer text-cblue-500' />
          ) : (
            <IconStar className='w-5 h-5 cursor-pointer text-cgray-500' />
          )}
        </Tippy>
      )}
    </div>

  );
}

export default FavouriteButton;
