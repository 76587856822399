import { useQuery } from '@apollo/client';
import { IconInfoSquareRounded } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import {
  PRODUCT_PRICE_SUGGESTION_QUERY,
} from '../../../../../../api/queries/quotations/product';
import { CURRENT_USER_QUERY } from '../../../../../../api/queries/users';
import { LoadingIndicator } from '../../../../../../layout';
import RegenerateButton from './RegenerateButton';

interface Props {
  productId: ID,
}

export default function ProductPriceSuggestion(props: Props) {
  const { productId } = props;
  const { t } = useTranslation();

  const {
    data: productPriceSuggestionData,
    loading: productPriceSuggestionLoading,
  } = useQuery(PRODUCT_PRICE_SUGGESTION_QUERY, {
    variables: {
      product: productId as string,
    },
  });

  const {
    data: userData,
    loading: userLoading,
  } = useQuery(CURRENT_USER_QUERY);


  if (productPriceSuggestionLoading || userLoading) {
    return (
      <div className='rounded-bl rounded-br border-cgray-300 flex text-xs 2xl:text-sm text-cgray-400'>
        <LoadingIndicator className="w-full flex justify-center items-center" />
      </div>
    );
  } else if (!productPriceSuggestionLoading
    && !userLoading
    && productPriceSuggestionData
    && productPriceSuggestionData.productPriceSuggestion
    && productPriceSuggestionData.productPriceSuggestion.response) {
    const suggestion = productPriceSuggestionData.productPriceSuggestion.response;
    return (
      <div className='pl-1 pr-2 w-full flex justify-between border-t pt-2'>
        {/* Left-aligned items */}
        <div className='flex justify-center items-center gap-x-1'>
          <span className='font-bold'>{t('Suggested Price per Piece')}</span>
          <Tippy content={`${t('for')} ${suggestion?.triggeredBy?.fullName}`} placement="top" >
            <IconInfoSquareRounded className='text-cblue-500 w-5 cursor-pointer' />
          </Tippy>
        </div>

        {/* Right-aligned items */}
        <div className='flex items-center'>
          <div className="text-gray-400 font-bold text-center">
            {suggestion.suggestedPrice > 0
              ? suggestion.suggestedPriceLabel
              : <span>{t('Not applicable')}</span>
            }
          </div>
          {suggestion?.triggeredBy?.id !== userData?.user?.id && (
            <div className='pl-1'>
              <RegenerateButton productId={productId} />
            </div>
          )}
        </div>
      </div>

    );
  } else {
    return (<></>);
  }
}
