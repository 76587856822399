import Tippy from '@tippyjs/react';
import React, { CSSProperties, Dispatch, SetStateAction, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getItemWidth } from '../../../../../helpers/dnd/utilities';
import { useAppDispatch, useAppSelector } from '../../../../../helpers/reduxHooks';
import { classNames, getListRowStyles, convertMinutesToTimeLabel } from '../../../../../helpers/utils';
import { setSelectedItem } from '../../../../../redux/quotationSlice';
import styles from '../../../../shared/dnd/TreeItem.module.css';
import { TreeItemIcon } from '../../../../shared/dnd/TreeItemIcon';
import { FlattenedItem } from '../../../../shared/dnd/types';
import RODefaultProductDetails from '../details/RODefaultProductDetails';
import HoveredContextMenu from '../HoveredContextMenu';
import WrapperRowProduct from './WrapperRowProduct';

interface Props {
  clone?: boolean;
  indicator?: boolean;
  wrapperRef?(node: HTMLLIElement): void;
  ghost?: boolean;
  disableSelection?: boolean;
  disableInteraction?: boolean;
  depth: number;
  handleProps?: object;
  indentationWidth: number;
  item: FlattenedItem;
  style: CSSProperties,
  showDetails: boolean,
  setShowDetails: Dispatch<SetStateAction<boolean>>,
}

const RODefaultRowProduct = forwardRef<HTMLDivElement, Props>(
  (
    {
      style, clone, depth, indentationWidth, indicator, item, showDetails, setShowDetails,
      wrapperRef, ghost, disableSelection, disableInteraction,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const copiedItem = useAppSelector(state => state.quotation.copiedItem);
    const selectedItem = useAppSelector(state => state.quotation.selectedItem);
    const itemToCut = useAppSelector(state => state.quotation.itemToCut);
    const maximumQuotationUncollapsedDepth = useAppSelector(state => state.quotation.maximumQuotationUncollapsedDepth);
    const dispatch = useAppDispatch();
    const [isItemHoveredId, setIsItemHovered] = useState<string | null>(null);

    const selectTreeItem = () => {
      dispatch(setSelectedItem(item));
    };

    const productPropsWrapper = {
      style, clone, depth, indentationWidth, indicator, ref,
      wrapperRef, ghost, disableSelection, disableInteraction,
    };

    return (
      <WrapperRowProduct {...productPropsWrapper} >
        <>
          <div
            onClick={selectTreeItem}
            className={classNames(styles.TreeItem)}
            style={getListRowStyles(style, item, copiedItem, selectedItem, itemToCut, showDetails) as React.CSSProperties}
            onMouseEnter={() => setIsItemHovered(item.id.toString())}
            onMouseLeave={() => setIsItemHovered(null)}
          >
            <div
              className='flex justify-end'
              style={{ width: `${getItemWidth(maximumQuotationUncollapsedDepth, indentationWidth, depth)}px` }}
            >
            </div>
            <div className='w-14 py-3.5 flex justify-between'>
              <TreeItemIcon
                item={item}
                setShowDetails={setShowDetails}
                showDetails={showDetails}
              />
            </div>
            <div className='flex items-center gap-2 2xl:gap-5 3xl:gap-8'>
              <Tippy content={item.position} placement="right" disabled={item.position !== undefined && item.position.length < 10}>
                <div className='w-20 overflow-clip text-ellipsis text-right' dir="rtl">{item.position}</div>
              </Tippy>
              <Tippy 
                content={['AL', 'OP'].includes(item.product?.positionType?.slice(0, 2) || '') ? t('Price is not included in the project calculation') : ''} 
                placement="top" 
                disabled={!['AL', 'OP'].includes(item.product?.positionType?.slice(0, 2) || '')}
              >
                <div className="w-16 pl-1">{item.product?.positionType.slice(0, 2)}</div>
              </Tippy>
              <div className='w-11 text-right'>{item.quantity}</div>
              <div className="w-32">{item.product?.externalId}</div>
              <Tippy content={item?.product?.description} disabled={!item?.product?.description || item?.product?.description.length < 60} placement="top">
                <div className="w-96 3xl:w-[450px] 4xl:w-[800px] truncate pl-2">{item?.product?.description}</div>
              </Tippy>
              <div className="w-20">{item.product?.manufacturingWage?.costLabel}</div>
              <Tippy content={t('Manufacturing time, per item')} placement="top" >
                <div className="w-12 text-center">{convertMinutesToTimeLabel(Number(item.product?.manufacturingTime))}</div>
              </Tippy>
            </div>
            <div className='flex items-center justify-between 3xl:grow'>
              <div className="w-16">{item.product?.profitSurcharge} %</div>
              <div className="w-16">{item.product?.supplierLabel}</div>
              <div className="w-20 pl-1">{item?.product?.productComputation?.netPricePieceLabel}</div>
              <div className="w-28 pl-1">{item?.product?.productComputation?.netSalesPricePieceLabel}</div>
              <div className="w-28 pl-1">{item?.product?.productComputation?.totalSalesPriceLabel}</div>
            </div>
            {isItemHoveredId === item.id && (
              <HoveredContextMenu
                item={item}
                isItemHoveredId={isItemHoveredId}
                setIsItemHoveredId={setIsItemHovered}
                showDetails={showDetails}
                setShowDetails={setShowDetails}
              />
            )}
          </div>
          {showDetails && (
            <RODefaultProductDetails
              externalProductId={item?.product?.externalId as ID}
              catalogId={item?.product?.catalogId as ID}
              indentationWidth={indentationWidth}
              depth={depth}
              maximumUncollapsedDepth={maximumQuotationUncollapsedDepth}
            />
          )}
        </>
      </WrapperRowProduct>
    );
  },
);

export default RODefaultRowProduct;
