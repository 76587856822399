import { useTranslation } from 'react-i18next';

interface NoResultsActionButtonProps {
  buttonText: string;
  onClick: () => void;
}

export default function NoResultsActionButton({ buttonText, onClick }: NoResultsActionButtonProps) {
  const { t } = useTranslation();
  
  return (
    <button
      onClick={onClick}
      type="button"
      className='w-48 h-6 flex justify-start items-center px-2 py-1 border border-cblue-500 rounded text-xs uppercase text-white bg-cblue-500 transition duration-150 hover:bg-cblue-700 hover:opacity-90 focus:opacity-100 active:bg-cblue-700 active:opacity-100'
    >
      {t(buttonText)}
    </button>
  );
} 