import { useMutation } from '@apollo/client';
import { IconArrowRight, IconArrowsRight } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { ItemTypeChoices, MultipleItemType } from '../../../../__generated__/graphql';
import { ADD_QUOTATION_ITEMS_MUTATION } from '../../../../api/mutations/quotations/item';
import { QUOTATION_QUERY } from '../../../../api/queries/quotations/quotation';
import { useAppSelector } from '../../../../helpers/reduxHooks';
import { classNames, prepareItemForMutation } from '../../../../helpers/utils';
import { LoadingIndicator } from '../../../../layout';
import { FlattenedItem } from '../../../shared/dnd/types';

interface Props {
  item?: FlattenedItem,
  pinnedItems?: FlattenedItem[],
}

export default function PasteArrow(props: Props) {
  const { item, pinnedItems } = props;

  const selectedItem = useAppSelector(state => state.quotation.selectedItem);
  const pasteSearchResultsBelowSelectedItem = useAppSelector(state => state.quotation.pasteSearchResultsBelowSelectedItem);
  const { quotationId } = useParams();
  const searchQuotationId = useAppSelector(state => state.search.QuotationContext.searchQuotationId);
  const selectedFavouriteGroupId = useAppSelector(state => state.search.selectedFavouriteGroupId);
  const isQuotationSearchContext = !!searchQuotationId || !!selectedFavouriteGroupId; 
  const refetchQueries = [
    { query: QUOTATION_QUERY,
      variables: {
        quotation: quotationId,
      }, 
    },
  ];
  
  const [
    addItemMutation,
    {
      loading: addItemMutationLoading,
    }] = useMutation(ADD_QUOTATION_ITEMS_MUTATION, { refetchQueries });

  const handlePasteTreeItem = () => {
    const items: { catalog?: string; externalId?: string; itemType: MultipleItemType; item?: string; }[] = [];
    if (item) {
      items.push(prepareItemForMutation(item, isQuotationSearchContext));      
    } else {
      pinnedItems?.forEach(itemForMutation => {
        items.push(prepareItemForMutation(itemForMutation, isQuotationSearchContext));      
      });
    }
    addItemMutation({
      variables: {
        items,
        order: pasteSearchResultsBelowSelectedItem ? selectedItem?.order as number + 1 : 0,
        parent: (!pasteSearchResultsBelowSelectedItem ? selectedItem?.id : selectedItem?.parentId) as string,
        quotation: quotationId as string,
      },
    });
  };

  // if item is Group or in pinnedItems there are only groups, then it can be pasted anywhere
  const isGroup = item?.itemType === ItemTypeChoices.Group || (pinnedItems && pinnedItems.every(pinnedItem => pinnedItem.itemType === ItemTypeChoices.Group));
  
  const canBePasted = selectedItem && ((selectedItem.parentId || !pasteSearchResultsBelowSelectedItem) || isGroup);

  const tippyContent = canBePasted 
    ? t('Paste to the selected Quotation') 
    : selectedItem && !((selectedItem.parentId || !pasteSearchResultsBelowSelectedItem) || isGroup) 
      ? t('Product/Service cannot be pasted on the root level')
      : t('Select item in Quotation');


  const Arrow = pinnedItems && pinnedItems.length > 0 ? IconArrowsRight : IconArrowRight;

  return (
    <div className="w-6 flex justify-end items-center absolute right-12">
      { !addItemMutationLoading && 
        <Tippy content={tippyContent} placement='left'>
        <Arrow 
          className={classNames(canBePasted ? 'cursor-pointer' : 'text-cgray-300')}
          onClick={() => canBePasted && handlePasteTreeItem()}

        />
      </Tippy>}
      {addItemMutationLoading && (
        <LoadingIndicator className="w-6 h-6 flex items-center justify-center" />
      )}
    </div>
  );
}