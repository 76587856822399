import { useQuery } from '@apollo/client';
import Tippy from '@tippyjs/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  GroupTemplateGroupFeatureType,
  QuotationGroupFeatureType,
  QuotationGroupDetailsType,
  ProjectsProjectProjectStatusChoices,
  QuotationVersionTypeChoices,
  QuotationsGroupManualSalesPricePiecePriceTypeChoices,
} from '../../../../../../__generated__/graphql';
import { PROJECT_QUERY } from '../../../../../../api/queries/projects';
import { QUOTATION_GROUP_DETAILS_QUERY } from '../../../../../../api/queries/quotations/details';
import { PRODUCTION_UNIT_STATUSES_QUERY } from '../../../../../../api/queries/quotations/group';
import { QUOTATION_QUERY } from '../../../../../../api/queries/quotations/quotation';
import { CURRENT_USER_QUERY } from '../../../../../../api/queries/users';
import { getItemWidth } from '../../../../../../helpers/dnd/utilities';
import { LoadingIndicator } from '../../../../../../layout';
import { Switch } from '../../../../../../layout/fields';
import FavouriteButton from './FavouriteButton';
import ROGroupFeatures from './groupFeature/ROGroupFeatures';
import GroupProductionUnitStatusSelect from './productionUnit/GroupProductionUnitStatusSelect';

interface Props {
  groupId: ID;
  indentationWidth: number;
  depth: number;
  maximumUncollapsedDepth: number;
}

export default function ROQuotationGroupDetails(props: Props) {
  // Is used only as Readonly Group Details on Quotation Page.
  const { groupId, indentationWidth, depth,
    maximumUncollapsedDepth } = props;
  const [group, setGroup] = useState<QuotationGroupDetailsType | undefined>();
  const { t } = useTranslation();

  const GROUP_DETAILS_QUERY = QUOTATION_GROUP_DETAILS_QUERY;

  const { projectId, quotationId } = useParams();

  const { data: statusesData } = useQuery(
    PRODUCTION_UNIT_STATUSES_QUERY,
  );

  const { data: userData } = useQuery(CURRENT_USER_QUERY);

  const {
    loading: groupLoading,
  } = useQuery(GROUP_DETAILS_QUERY, {
    variables: {
      group: groupId as string,
    },
    onCompleted: (data) => {
      if (data && data.quotationGroupDetails && data.quotationGroupDetails.response) {
        setGroup(data.quotationGroupDetails.response as QuotationGroupDetailsType);
      }
    },
  });

  const {
    data: projectData,
    loading: projectLoading,
  } = useQuery(PROJECT_QUERY, {
    variables: {
      project: projectId as string,
    },
  });

  const {
    data: quotationData,
    loading: quotationLoading,
  } = useQuery(QUOTATION_QUERY, {
    variables: {
      quotation: quotationId as string,
    },
  });

  const refetchQueries = [{
    query: QUOTATION_QUERY,
    variables: {
      quotation: quotationId as string,
    },
  },
  {
    query: QUOTATION_GROUP_DETAILS_QUERY,
    variables: {
      group: groupId as string,
    },
  },
  ];

  const PUStatusOptions = statusesData && statusesData.productionUnitStatuses && statusesData.productionUnitStatuses.response
    ? statusesData.productionUnitStatuses.response.map((status) => (
      { value: status?.id, label: status?.title }) as SelectOption)
    : [];

  
  const manualPriceTypeOptions: SelectOption[] = Object.values(QuotationsGroupManualSalesPricePiecePriceTypeChoices).map(
    (priceType) => ({
      label: t(priceType.charAt(0).toUpperCase() + priceType.slice(1).toLowerCase()),
      value: priceType,
    }),
  );


  const project = projectData?.project?.response;
  const quotation = quotationData?.quotation?.response;

  const user = userData && userData.user;

  if (groupLoading) {
    return (
      <div className='p-2.5 pr-5 border-[1px] border-t-0 rounded-bl rounded-br border-cgray-300 flex text-xs 2xl:text-sm text-cgray-400'>
        <LoadingIndicator className="w-full flex h-56 justify-center items-center" />
      </div>
    );
  } else if (!quotationLoading && !groupLoading && !projectLoading && group) {
    return (
      <div className='p-2.5 pr-5 flex border border-t-0 rounded-bl rounded-br border-cgray-300 text-xs 2xl:text-sm text-cgray-400 z-20 bg-white'>
        <div
          className="flex justify-end"
          style={{ width: `${getItemWidth(maximumUncollapsedDepth, indentationWidth, depth)}px` }}
        ></div>
        <div className="flex flex-col w-full gap-2 text-sm text-cgray-600 whitespace-normal">
          <div className="grid grid-cols-12 w-full gap-2 text-sm text-cgray-600">
            <div className='col-span-4 flex flex-col gap-2'>
              <div className="mb-2 font-bold text-cgray-600 flex">
                <span className='mr-1'>{t('General')}</span>
                {user?.isSuperuser && (
                  <FavouriteButton
                    groupId={group.id}
                    isFavourite={!!group?.isFavourite}
                  />
                )}
                </div>
              <div className='h-10 cursor-default col-span-4 flex flex-col pl-2 py-px rounded-t border border-cgray-200'>
                <p className="text-2xs text-cgray-400">{t('Description')}</p>
                <p className='text-sm overflow-auto grow'>{group?.description}</p>
              </div>
              <div className='h-36 cursor-default col-span-4 flex flex-col pl-2 py-px rounded-t border border-cgray-200'>
                <p className="text-2xs text-cgray-400">{t('Notes')}</p>
                <p className='text-sm overflow-auto grow'>{group?.notes}</p>
              </div>
            </div>
            <div className='col-span-4 flex flex-col gap-2'>
              <div className="mb-2 font-bold text-cgray-600">{t('ETIM')}</div>
              <div className='h-10 cursor-default col-span-4 flex flex-col pl-2 py-px rounded-t border border-cgray-200'>
                <p className="text-2xs text-cgray-400">{t('ETIM Class')}</p>
                <p className='text-sm overflow-auto grow'>{group?.etimClass?.localizedDescription}</p>
              </div>
              <div className='col-span-4 flex gap-2 flex-wrap'>
                <ROGroupFeatures
                  groupFeatures={
                    group.groupFeatures
                      ? group.groupFeatures as QuotationGroupFeatureType[] | GroupTemplateGroupFeatureType[]
                      : []
                  }
                />
              </div>
            </div>
            <div className='grid col-grid-2 col-span-4 gap-y-4'>
              <div>
                <div className='mb-2 font-bold text-cgray-600'>{t('Production')}</div>
                <div className='flex flex-col gap-4'>
                  <div className='pt-4'>
                    <div className="flex items-center">
                      <Tippy content={t('Can not be changed for readonly quotation')} >
                        <Switch
                          className='flex items-center gap-2 shrink-0'
                          //@ts-ignore
                          value={group.isProductionUnit}
                          isDisabled
                          onChange={() => { }}
                          label={t('Is Production Unit?')}
                        />
                      </Tippy>
                    </div>
                  </div>
                  <div className='w-2/4'>
                    {!PUStatusOptions.length
                      ? (
                        <div className='p-6 bg-cyellow-200 text-cred-700 rounded shadow'>
                          <div>{t('No Statuses for Production Units available.')}</div>
                          <div>{t('Please contact your administrator.')}</div>
                        </div>

                      )
                      : (
                        quotation?.versionType === QuotationVersionTypeChoices.Readonly || project?.projectStatus !== ProjectsProjectProjectStatusChoices.Production || !group.isProductionUnit
                          ? (
                            <Tippy content={t('Production Unit Status can be changed only if Project Status is "Production"')} placement="top" >
                              <div className='cursor-default col-span-4 flex flex-col pl-2 py-px rounded-t border border-cgray-200'>
                                <p className="text-2xs text-cgray-400">{t('Production Unit Status')}</p>
                                <p className='text-sm overflow-hidden grow h-5'>{group?.productionUnitStatus?.title}</p>
                              </div>
                            </Tippy>
                          )
                          : (
                            <GroupProductionUnitStatusSelect
                              group={group}
                              quotationId={quotationId as string}
                              refetchQueries={refetchQueries}
                              options={PUStatusOptions}
                            />
                          )
                      )
                    }
                  </div>
                </div>
              </div>
              <div>
                <div className="font-bold text-cgray-600">{t('Manual Price')}</div>
                <div className='flex flex-col gap-4'>
                  <div className='pt-4'>
                    <Tippy content={t('Can not be changed for readonly quotation')} >
                        <Switch
                          className='flex items-center gap-2 shrink-0'
                          //@ts-ignore
                          value={group.hasManualSalesPricePiece}
                          isDisabled
                          onChange={() => { }}
                          label={t('Set manual Price?')}
                        />
                      </Tippy>
                  </div>
                  <div className='grid grid-cols-4 gap-x-2'>
                    <div className='col-span-3'>
                          <div className='cursor-default col-span-4 flex flex-col pl-2 py-px rounded-t border border-cgray-200'>
                            <p className="text-2xs text-cgray-400">{t('Price type')}</p>
                            <p className='text-sm overflow-hidden grow h-5'>
                              {manualPriceTypeOptions.find(item => item.value === group?.manualSalesPricePiece?.priceType)?.label || ''}
                            </p>

                          </div>
                    </div>
                    <div>
                          <div className='cursor-default col-span-4 flex flex-col pl-2 py-px rounded-t border border-cgray-200'>
                            <p className="text-2xs text-cgray-400">{t('Price Value')}</p>
                            <p className='text-sm overflow-hidden grow h-5'>
                              {group.manualSalesPricePiece?.valueLabel}
                            </p>
                          </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (<></>);
  }
}