import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import { SelectedColumnName } from '../../../../constants';
import { useAppSelector } from '../../../../helpers/reduxHooks';
import { classNames } from '../../../../helpers/utils';


export default function DefaultHeader() {
  const { t } = useTranslation();
  const selectedColumn = useAppSelector(state => state.quotation.selectedColumn);
  
  return (
    <>
      <div className='flex items-center gap-2 2xl:gap-5 3xl:gap-8'>
        <div className={classNames('w-20', selectedColumn === SelectedColumnName.POSITION && 'text-cblue-500 text-base')}>{t('Position')}</div>
        <Tippy content={t('Prices are only displayed and calculated in higher-level hierarchies for "Normal positions" (NO) and "Addendum positions" (AD).  "Optional positions" (OP) and "Alternative positions" (AL) display prices but are not included in the project calculation.')} placement="top">
          <div className={classNames('w-16', selectedColumn === SelectedColumnName.POSITION_TYPE && 'text-cblue-500 text-base')}>{t('Type')}</div>
        </Tippy>
        <div className={classNames('w-11', selectedColumn === SelectedColumnName.QUANTITY && 'text-cblue-500 text-base')}>{t('Qty')}</div>
        <div className="w-32">{t('Product ID')}</div>
        <div className={classNames('w-96 3xl:w-[450px] 4xl:w-[800px]', selectedColumn === SelectedColumnName.DESCRIPTION && 'text-cblue-500 text-base')}>{t('Description')}</div>
        <div className={classNames('w-20', selectedColumn === SelectedColumnName.WAGE && 'text-cblue-500 text-base')}>{t('Wage')}</div>
        <div className={classNames('w-12', selectedColumn === SelectedColumnName.TIME && 'text-cblue-500 text-base')}>{t('Time')}</div>
      </div>
      <div className='flex items-center justify-between 3xl:grow'>
        <Tippy content={t('Profit Surcharge')} placement="top">
          <div className={classNames('w-16', selectedColumn === SelectedColumnName.SURCHARGE && 'text-cblue-500 text-base')}>{t('Profit')}</div>
        </Tippy>
        <div className="w-16">{t('Supplier')}</div>
        <Tippy content={t('Purchase Price per Piece')} placement="top">
          <div className="w-20">{t('PP/Piece')}</div>
        </Tippy>
        <Tippy content={t('Sales Price per Piece')} placement="top">
          <div className="w-28">{t('SP/Piece')}</div>
        </Tippy>
        <Tippy content={t('Total Sales Price')} placement="top">
          <div className="w-28">{t('Total SP')}</div>
        </Tippy>
      </div>
    </>
  );
}
