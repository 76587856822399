import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { UPDATE_SERVICE_PROFIT_SURCHARGE } from '../../../../../api/mutations/quotations/service';
import { SelectedColumnName } from '../../../../../constants';
import { useQuotationItemInputHandler } from '../../../../../helpers/customHooks';
import { useAppDispatch, useAppSelector } from '../../../../../helpers/reduxHooks';
import { classNames, handleInputKeyDown } from '../../../../../helpers/utils';
import { setSelectedColumn } from '../../../../../redux/quotationSlice';

interface Props {
  serviceId: ID,
  profitSurcharge: number,
  label?: boolean,
  quotationId: ID,
  refetchQueries: InternalRefetchQueriesInclude,
}

interface FormValues {
  profitSurcharge: number,
  service: ID,
  quotation: ID,
}

export default function ProfitSurchargeInput(props: Props) {
  const { serviceId, profitSurcharge, label, quotationId, refetchQueries } = props;
  const selectedColumn = useAppSelector(state => state.quotation.selectedColumn);
  const selectedItem = useAppSelector(state => state.quotation.selectedItem);
  const fieldName = 'profitSurcharge';
  const [inputFocused, setInputFocused] = useState(false);
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [isClickedTwice, setIsClickedTwice] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // For vertical moving
  useEffect(() => {
    if (selectedColumn === SelectedColumnName.SURCHARGE && selectedItem?.service?.id === serviceId) {
      inputRef.current?.focus();
    }
  }, [selectedItem, selectedColumn, serviceId]);

  const validationSchema = yup.object({
    [fieldName]: yup.number()
      .transform((_, transformedValue) => {
        if (typeof transformedValue === 'string') {
          const numberValue = parseFloat(transformedValue);
          if (isNaN(numberValue)) {
            return undefined; // return original string if it's not a valid number
          }
          // If it is a valid number, format it to two decimal places
          return parseFloat(numberValue.toFixed(2));
        }
        // If transformedValue is already a number, just return it
        return transformedValue;
      })
      .required(t('Required')),
  });

  const initialValues: FormValues = {
    profitSurcharge: profitSurcharge,
    service: serviceId,
    quotation: quotationId,
  };

  const { handleChange, handleSubmit, values, handleBlur, isError, setFieldValue } = useQuotationItemInputHandler({
    mutation: UPDATE_SERVICE_PROFIT_SURCHARGE,
    fieldName,
    validationSchema,
    initialValues,
    refetchQueries,
    setInputFocused,
    inputFocused,
  });

  const handleClick = () => {
    if (inputRef.current) {
      if (isClickedOnce && !isClickedTwice) {
        setIsClickedTwice(true);
      } else if (!isClickedOnce) {
        inputRef.current.focus();
        setInputFocused(true);
        setIsClickedOnce(true);
        dispatch(setSelectedColumn(SelectedColumnName.SURCHARGE));
      }
    }
  };

  const handleInputBlur = () => {
    handleBlur();
    setIsClickedTwice(false);
    setInputFocused(false);
    setIsClickedOnce(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    handleInputKeyDown({
      e,
      isClickedTwice,
      isClickedOnce,
      inputFocused,
      inputRef,
      handleSubmit,
      handleInputBlur,
      setIsClickedTwice,
      setFieldValue,
      fieldName,
      originalValue: profitSurcharge,
    });
  };

  return (
    <div
      className={classNames(
        inputFocused && 'bg-cgray-200 border-cblue-500',
        isError && 'border-cred-500',
        'border-b relative pl-1 h-full flex items-center justify-center rounded-t bg-cgray-100 hover:bg-cgray-200 cursor-text group',
      )}
      onClick={handleClick}
    >
      {label && (
        <label
          htmlFor={fieldName}
          className={classNames(
            isError ? 'text-cred-500' : 'text-cgray-400',
            'absolute top-0 left-2 text-2xs cursor-text',
          )}
          onClick={handleClick}
        >
          {t('Profit Surcharge')}
        </label>
      )}
      <input
        name={fieldName}
        value={values[fieldName]}
        onChange={handleChange}
        className="p-0 w-full min-h-[38px] text-sm text-right border-none rounded-t ring-0 outline-none bg-cgray-100 group-hover:bg-cgray-200 focus:bg-cgray-200 focus:ring-0"
        ref={inputRef}
        readOnly={!isClickedTwice}
        onFocus={() => {
          setInputFocused(true);
          dispatch(setSelectedColumn(SelectedColumnName.SURCHARGE));
        }}
        onBlur={handleInputBlur}
        onKeyDown={handleKeyDown}
      />
      <span className="text-gray-400 text-sm px-1.5">%</span>
    </div>
  );
}
