/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconChevronDown, IconX } from '@tabler/icons-react';
import { useState } from 'react';
import { components } from 'react-select';
import type { GroupBase, InputProps } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { classNames } from '../../helpers/utils';

interface Props {
  placeholder?: string,
  isMulti?: boolean,
  isLoading?: boolean,
  isClearable?: boolean,
  onChange?: (value: SelectOption) => void,
  onMultiChange?: (value: SelectOption[]) => void,
  onInputChange?: (value: string) => void,
  value?: SelectOption,
  showLabel?: boolean,
  showLabelInitially?: boolean,
  defaultValue?: SelectOption | SelectOption[],
  isDisabled?: boolean;
  loadOptions: any
}

function DropdownIndicator(props: any) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <IconChevronDown 
        className={classNames(props.isDisabled ? 'text-cgray-500 hover:text-cgray-500 focus:text-cgray-500' : 'text-cblue-500 hover:text-cblue-500 focus:text-cblue-500', 'h-6 w-6')} 
      />
    </components.DropdownIndicator>
  );
}

function ClearIndicator(props: any) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.ClearIndicator {...props}>
      <IconX className="-mr-2 h-5 w-5 text-cgray-400 hover:text-cblue-500 cursor-pointer" />
    </components.ClearIndicator>
  );
}

function Input(props: JSX.IntrinsicAttributes & InputProps<unknown, boolean, GroupBase<unknown>>) {
  return (
    <components.Input {...props} 
      style={{
        color: '#282828',
        caretColor: '#005DA3',
        background: '0px center',
        opacity: 1,
        gridArea: '1 / 2 / auto / auto',
        minWidth: '200px',
        border: '0px',
        margin: '0px',
        outline: '0px',
        padding: '0px',
        boxShadow: 'none',
      }} 
    />
  );
}

function AsyncSelectInput(props: Props) {
  const {
    placeholder, isMulti, onChange, onInputChange, isLoading, isDisabled,
    showLabel: showLabelInitially, value, defaultValue, onMultiChange, isClearable,
    loadOptions,
  } = props;

  const [showLabel, setShowLabel] = useState(!!showLabelInitially);
  const [selectFocused, setSelectFocused] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleChange = (selectedOption: SelectOption) => {
    const isCurrentSelected = !!selectedOption;
    setShowLabel(isCurrentSelected);
    if (onChange) {
      onChange(selectedOption);
    }
  };

  const handleMultiSelectChange = (selectedOption: SelectOption[]) => {
    const isCurrentSelected = !!selectedOption?.length;
    setShowLabel(isCurrentSelected);
    if (onMultiChange) {
      onMultiChange(selectedOption);
    }
  };

  return (
    <div className="relative">
      <AsyncPaginate
        value={value || undefined}
        defaultValue={defaultValue}
        components={{ DropdownIndicator, ClearIndicator, Input }}
        placeholder={placeholder}
        loadOptions={loadOptions}
        additional={{ skip: 0 }}
        defaultOptions
        debounceTimeout={1000}
        isMulti={!!isMulti}
        isLoading={isLoading}
        isClearable={!!isClearable}
        onInputChange={onInputChange}
        filterOption={() => true}
        isDisabled={isDisabled}
        // @ts-ignore
        onChange={isMulti ? handleMultiSelectChange : handleChange}
        onFocus={() => setSelectFocused(true)}
        onBlur={() => setSelectFocused(false)}
        styles={{
          control: (styles, { isFocused }) => ({
            ...styles,
            fontSize: '14px',
            backgroundColor: isFocused ? '#e9e9e9' : '#f4f4f4',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            border: 0,
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#e9e9e9',
            },
          }),
          placeholder: (styles) => ({
            ...styles,
            color: '#b2b2b2',
            fontWeight: 500,
            fontSize: 14,
          }),
          input: (styles) => ({
            ...styles,
            color: '#282828',
          }),
          indicatorsContainer: (styles) => ({
            ...styles,
            padding: 0,
          }),
          dropdownIndicator: (styles, state) => ({
            ...styles,
            padding: 0,
            margin: '8px 8px 8px 0px',
            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
            transition: 'all .15s ease',
          }),
          clearIndicator: (styles) => ({
            ...styles,
            display: `${value?.value ? 'block' : 'none'}`,
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          menu: (styles) => ({
            ...styles,
            backgroundColor: '#ffffff',
            boxShadow: '0px 6px 6px #d3d3d3',
            paddingTop: 0,
            marginTop: 0,
            border: 'none',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            zIndex: 30,
          }),
          menuList: (styles) => ({
            ...styles,
            padding: 0,
            margin: 0,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
          }),
          option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            color: '#282828',
            backgroundColor: isSelected
              ? '#caddeb'
              : isFocused
                ? '#e4edf5'
                : '#ffffff',
          }),
          valueContainer: (styles) => ({
            ...styles,
            paddingBottom: 0,
            boxSizing: 'border-box',
          }),
          singleValue: (styles) => ({
            ...styles,
            color: '#282828',
          }),
          multiValue: (styles) => ({
            ...styles,
            margin: '10px 1px 0 0',
            borderRadius: '4px',
            border: 'solid 1px',
            borderColor: '#e4e4e7',
            backgroundColor: 'white',
          }),
          multiValueLabel: (styles) => ({
            ...styles,
            color: '#005DA3',
            padding: '1px 3px 1px',
          }),
          multiValueRemove: (styles) => ({
            ...styles,
            padding: 0,
            color: '#b2b2b2',
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#caddeb',
            primary50: '#caddeb',
          },
        })}
      />
      <div className={classNames(
        selectFocused ? 'bg-cblue-500' : 'bg-transparent',
        'absolute bottom-0 left-0 h-px w-full',
      )}
      />
      {(value || showLabel) && (
        <div
          onClick={()=>setMenuIsOpen(!menuIsOpen)}
          className={classNames(selectFocused ? 'text-cblue-500' : 'text-cgray-400', 'absolute top-0.5 left-2.5 text-2xs cursor-default')}>
          <div className='truncate w-full'>
            {placeholder}
          </div>
        </div>
      )}
    </div>
  );
}

export default AsyncSelectInput;
