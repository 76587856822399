/* eslint-disable @typescript-eslint/no-explicit-any */
import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import type { DocumentNode } from 'graphql';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { AnyObjectSchema } from 'yup';
import { PROJECT_QUERY } from '../api/queries/projects';
import { FlattenedItem } from '../components/shared/dnd/types';
import { discardAlert, setErrorAlert, setSuccessAlert } from '../redux/alertSlice';
import {
  setIsLoadingUpdate,
  setSelectedItem,
  unsetSelectedItem,
} from '../redux/quotationSlice';
import { useAppDispatch, useAppSelector } from './reduxHooks';

type SingleValue = string | number;
type ArrayValue = (string | number)[];

interface UseQuotationItemInputHandlerOptions {
  mutation: DocumentNode;
  fieldName: string;
  validationSchema: AnyObjectSchema;
  initialValues: Record<string, any>;
  refetchQueries: InternalRefetchQueriesInclude,
  setInputFocused?: (value: boolean) => void,
  inputFocused?: boolean,
}

const useDebounceCommon = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useDebounceValue = (value: SingleValue, delay: number) => {
  return useDebounceCommon<SingleValue>(value, delay);
};

export const useDebounceArray = (value: ArrayValue, delay: number) => {
  return useDebounceCommon<ArrayValue>(value, delay);
};

export const useTreeItemLogic = (item: FlattenedItem) => {
  const dispatch = useAppDispatch();
  const copiedItem = useAppSelector(state => state.quotation.copiedItem);
  const selectedItem = useAppSelector(state => state.quotation.selectedItem);

  useEffect(() => {
    if (!copiedItem) {
      dispatch(unsetSelectedItem());
    }
  }, [dispatch, copiedItem]);

  const selectItem = () => {
    dispatch(setSelectedItem(item));
  };

  const unselectItem = () => {
    dispatch(setSelectedItem(item));
  };

  const toggleItem = () => {
    if (item.id === selectedItem?.id) {
      dispatch(unsetSelectedItem());
    } else {
      dispatch(setSelectedItem(item));
    }
  };

  return { copiedItem, selectedItem, selectItem, unselectItem, toggleItem };
};

export const useQuotationItemInputHandler = (options: UseQuotationItemInputHandlerOptions) => {
  const {
    mutation, fieldName, validationSchema,
    initialValues, refetchQueries, setInputFocused, inputFocused } = options;
  const dispatch = useAppDispatch();
  // we use formikAlert to keep highlighting field as one that has an error even after 
  // formik errors state will be cleared out after we restore previous value for this field. 
  // And we restore this previous value if current value failed validation.
  const [formikAlert, setFormikAlert] = useState(false);
  const [mutationError, setMutationError] = useState(false);
  const alert = useAppSelector(state => state.alert.alert);
  const { t } = useTranslation();

  const [mutate, { data, loading }] = useMutation(
    mutation,
    {
      refetchQueries: refetchQueries,
    },
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: () => {
      // Casts values to the type specified in schema.
      const castedValues = validationSchema.cast(formik.values);
      const variables = {
        ...castedValues,
        itemProfitSurcharge: parseFloat(castedValues.itemProfitSurcharge),
      };

      if (formik.values[fieldName] !== initialValues[fieldName]) {
        // discard Alert after each mutation. In case of mutation error new alert will appear. 
        dispatch(discardAlert());
        mutate({ variables: variables })
          .catch(() => {
            formik.setFieldValue(fieldName, initialValues[fieldName]);
          });
      }
    },
  });

  const handleBlur = () => {
    // if the current value === initial value, no error alert should be shown 
    // (user edited field value to the initial state, that is correct by default)
    if (formik.values[fieldName] === initialValues[fieldName]) {
      dispatch(discardAlert());
      setMutationError(false);
    }
    try {
      validationSchema.validateSyncAt(fieldName, formik.values);
      formik.handleSubmit();
    } catch (e) {
      formik.setFieldValue(fieldName, initialValues[fieldName]);
    }
    if (setInputFocused) {
      setInputFocused(false);
    }
  };

  useEffect(() => {
    dispatch(setIsLoadingUpdate(loading));
  }, [dispatch, loading]);

  useEffect(() => {
    // Show errors using redux action.
    if (!!Object.keys(formik.errors).length && !inputFocused) {
      const errors = Object.entries(formik.errors).map(([, value]) => `${value}`);
      setFormikAlert(true);
      dispatch(setErrorAlert(errors));
    }
  }, [dispatch, formik.errors, inputFocused]);

  useEffect(() => {
    if (!alert?.id) {
      setFormikAlert(false);
    }
  }, [alert]);

  useEffect(() => {
    if (data) {
      const key = Object.keys(data)[0];
      setMutationError(data[key].errors?.length);
      // show Success Alert if there are no errors
      if (!data[key].errors?.length) {
        dispatch(setSuccessAlert([`${t('Field was successfully updated')}`]));
      }
    }
  }, [data, dispatch, t]);

  return {
    ...formik,
    handleBlur,
    isError: mutationError || formikAlert,
  };
};

export const useProjectChange = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  
  const [getProject, { data: projectData }] = useLazyQuery(PROJECT_QUERY);
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);

  const handleProjectChange = useCallback((selectedId: string) => {
    setSelectedProjectId(selectedId);
    // We force here to fetch project and its quotations only from the network
    // because we need to update apollo cache state on latest PU status update
    // that possibly lead to project status change and redirect to new quotation page.
    getProject({
      variables: { project: selectedId },
      fetchPolicy: 'network-only',
    });
  }, [getProject]);

  useEffect(() => {
    if (selectedProjectId && projectData) {
      const project = projectData?.project?.response;
      if (project && !!project.quotations?.length) {
        const latestQuotation = project.quotations[0];
        navigate(`/quotation/${project.id}/${latestQuotation?.id}/`);
      } else if (project) {
        navigate('/projects/');
        dispatch(setErrorAlert([t('Quotation not found')]));
      }
    }
    // remove navigate to avoid useNavigate re-render bug: 
    // https://github.com/remix-run/react-router/issues/8349
    // https://github.com/remix-run/react-router/issues/7634
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData, selectedProjectId, dispatch, t]);

  return handleProjectChange;
};
